import React, {useEffect, useState} from "react";
import {Container, Form, Button, Card, InputGroup, FormControl, Alert} from "react-bootstrap";
import NavbarSite from "../components/NavbarSite";
import {getClientByUserId, sendContactForm} from "../services/Client";
import {getUserById} from "../services/user";
import Spinner from "react-bootstrap/Spinner";

import '../css/DemandeModification.css';
import PageContainer from "../components/PageContainer";

function DemandeModification() {
    const [sujets, setSujets] = useState([
        { id: 1, name: "Changement d'adresse", attachmentMessage: "Merci de joindre un justificatif de domicile de moins de six mois" },
        { id: 2, name: "Changement d'adresse mail", attachmentMessage: "" },
        { id: 3, name: "Changement de RIB", attachmentMessage: "Merci de joindre votre nouveau RIB" },
        { id: 4, name: "Demande d'information", attachmentMessage: "" },
        { id: 5, name: "Autre (Merci préciser dans le message)", attachmentMessage: "" },
    ]);
    const [subject, setSubject] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [client, setClient] = useState(null);
    const [user, setUser] = useState(null);
    const [sent, setSent] = useState(false)
    const [error, setError] = useState(null);
    const [inputKey, setInputKey] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const [attachments, setAttachments] = React.useState([]);


    useEffect(() => {
        async function loadData() {
                const resUser = await getUserById();
                const resClient = await getClientByUserId();
                setUser(resUser);
                setClient(resClient);
                setIsLoading(false);
        }
        loadData();
    }, []);


    const handleSubmit = async (e) => {
    e.preventDefault();

        const formData = new FormData();
        formData.append("name", client.firstName + " " + client.lastName);
        formData.append("email", user.email);
        formData.append("subject", sujets.find((s) => s.id === parseInt(subject)).name);
        formData.append("message", message);
        attachments.forEach((attachment, index) => {
            if (attachment) {
                formData.append(`attachments`, attachment);
            }
        });

        try {
            const response = await sendContactForm(formData);
            setSent(true);
            setSubject("");
            setMessage("");
            setAttachments([]);
            setTimeout(() => setSent(false), 3000);
            setInputKey((prevKey) => prevKey + 1);
        } catch (error) {
            setError(error.message);
            setTimeout(() => setError(null), 3000);
        }
  };

    const getAttachmentMessage = () => {
        const selectedSubject = sujets.find((s) => s.id === parseInt(subject));
        return selectedSubject?.attachmentMessage;
    };

    const handleAddAttachment = () => {
        if (attachments.length < 5) {
            setAttachments([...attachments, null]);
        }
    };

    const handleAttachmentChange = (index, file) => {
        const updatedAttachments = [...attachments];
        updatedAttachments[index] = file;
        setAttachments(updatedAttachments);
    };

    return (
        <PageContainer isLoading={isLoading}>
            {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <Container className="my-5">
                    <h1 className="text-center mb-5" style={{ color: '#092A6C' }}>Demande de modification</h1>
                    <Card className="shadow">
                        <Card.Body>
                            {sent && <Alert variant="success">Votre message a bien été envoyé.</Alert>}
                            {error && <Alert variant="danger">Une erreur est survenue.</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="subjectSelect">
                                    <Form.Label>Sujet</Form.Label>
                                    <Form.Control className="mb-3" as="select" value={subject} onChange={(e) => setSubject(e.target.value)}>
                                        <option value="" disabled>Sélectionnez un sujet</option>
                                        {sujets.map((s) => (
                                            <option key={s.id} value={s.id}>
                                                {s.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="messageTextarea">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="attachmentInput">
                                    <Form.Label>
                                        Pièce jointe {getAttachmentMessage() && `(${getAttachmentMessage()})`}
                                    </Form.Label>
                                    <InputGroup key={0} className="mb-2">
                                        <FormControl
                                            type="file"
                                            key={inputKey}
                                            onChange={(e) => handleAttachmentChange(0, e.target.files[0])}
                                            required={!!getAttachmentMessage()}
                                        />
                                    </InputGroup>
                                    {attachments.map((_, index) => (
                                        index !== 0 && (
                                            <InputGroup key={index} className="mb-2">
                                                <FormControl
                                                    type="file"
                                                    key={inputKey}
                                                    onChange={(e) => handleAttachmentChange(index, e.target.files[0])}
                                                    required={index === 0 && !!getAttachmentMessage()}
                                                />
                                            </InputGroup>
                                        )
                                    ))}
                                    {attachments.length < 4 && (
                                        <Button
                                            variant="secondary"
                                            onClick={handleAddAttachment}
                                            className="mt-2"
                                        >
                                            Ajouter une pièce jointe
                                        </Button>
                                    )}
                                </Form.Group>
                                <Button className="btn-custom" type="submit" disabled={!subject || !message} >Envoyer</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
            )}
        </PageContainer>
    );
}

export default DemandeModification;

