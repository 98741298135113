import React, { useState, useEffect } from "react";
import { BsDownload } from "react-icons/bs";
import {Tab, Row, Col, Nav, Container, Card} from "react-bootstrap";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import '../css/Reportings.css';
import Spinner from "react-bootstrap/Spinner";
import { checkLogin, downloadFileReporting, getAssembly, getCommunicated, getReportings, loginReporting } from "../services/Reportings";
import { FiEye, FiEyeOff } from "react-icons/fi";

function Reporting() {
    const [reportingsList, setReportingsList] = useState([]);
    const [communicatedList, setCommunicatedList] = useState([]);
    const [assemblyList, setAssemblyList] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [activeTab, setActiveTab] = useState("reportings");
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [authenticated, setAuthenticated] = useState(false);
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoadingLogin, setIsLoadingLogin] = useState(false);
    const [showPassword, setShowPassword] = useState(false);



    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoadingLogin(true);
        try {
            const response = await loginReporting({
                password,
            });
            if (response.status === 200) {
                setAuthenticated(true);
            } 
        } catch (error) {
            setErrorMessage(error.response.data.error || "Une erreur s'est produite. Veuillez réessayer.");
        } finally {
            setIsLoadingLogin(false); // Fin du chargement (succès ou échec)
        }
      };

      const handleInputChange = () => {
        setErrorMessage("");
    };

    useEffect(() => {
        
        async function getProductLoad() {
            const checkLog = await checkLogin();
            if (checkLog.checkLogin === true) {
                setAuthenticated(true);
            }
            if (authenticated === true) {
                const res = await getReportings();
                const resCommunicated = await getCommunicated();
                const resAssembly = await getAssembly();
                setReportingsList(res);
                setCommunicatedList(resCommunicated);
                setAssemblyList(resAssembly);
            }
            setIsLoading(false);
        }
        getProductLoad();
    }, [authenticated]);


    useEffect(() => {
        setSearch("");
    }, [activeTab]);

    const uniqueReportings = () => {
        return reportingsList
            ? Array.from(new Set(reportingsList.map((product) => product.productName)))
                .map(
                (productName) => {
                    return reportingsList.find(
                        (product) => product.productName === productName
                    );
                }
            )
            : [];
    };

    const uniqueCommunicated = () => {
        return communicatedList
            ? Array.from(
                new Set(communicatedList.map((product) => product.productName))
            )
                .sort()
                .map((productName) => {
                return communicatedList.find(
                    (product) => product.productName === productName
                );
            })
            : [];
    };

    const uniqueAssembly = () => {
        return assemblyList
            ? Array.from(new Set(assemblyList.map((product) => product.productName)))
                .map(
                (productName) => {
                    return assemblyList.find(
                        (product) => product.productName === productName
                    );
                }
            )
            : [];
    };

    const handleProductSelect = (productId) => {
        setSelectedProduct(productId);
    };

    const renderDocuments = (list) => {
        if (!selectedProduct) return null;

        const product = list.find((product) => product.id === selectedProduct);


        return (
            <div className={`documents-list${selectedProduct ? " show" : ""}`}>
                {product.documentProducts.length > 1 ? (
                    <button
                        className={`download-all-btn`}
                        onClick={() => downloadAllFiles(selectedProduct, list)}
                    >
                        Tout télécharger
                    </button>
                ) : null}

                {/*     <button
                    className={`download-all-btn${product.documentProducts.length === 0 ? " disabled" : ""}`}
                    onClick={() => downloadAllFiles(selectedProduct, list)}
                    disabled={product.documentProducts.length >= 1}
                    //disabled={product.documentProducts.length > 1}
                >
                    Tout télécharger
                </button>*/}

                {list
                    .filter((product) => product.id === selectedProduct)
                    .flatMap((product) => product.documentProducts)
                    .map((document) => {
                        if (document.path) {
                            const urlArray = document.path.split('/');
                            const numberOfSubfolders = urlArray.length - 2;
                            const fileName = urlArray[urlArray.length - 1];
                            const filePath = numberOfSubfolders === 1
                                ? `${urlArray[1]}/${fileName}`
                                : `${urlArray[1]}/${urlArray[2]}/${fileName}`;

                            return (
                                <button
                                    key={document.id}
                                    onClick={() => {
                                        handleDownload(filePath, fileName);
                                    }}
                                    className="document-link"
                                >
                                    <BsDownload className="download-icon" />
                                    {document.documentName}
                                </button>
                            );
                        } else {
                            console.error('URL du document manquante:', document);
                            return null;
                        }
                    })}
            </div>
        );
    };

    const downloadAllFiles = async (productId, list) => {
        try {
            const product = list.find((product) => product.id === productId);
            const zip = new JSZip();

            const files = await Promise.all(
                product.documentProducts.map(async (document) => {
                    const urlArray = document.path.split("/");
                    const numberOfSubfolders = urlArray.length - 2;
                    const fileName = urlArray[urlArray.length - 1];
                    const filePath = numberOfSubfolders === 1
                        ? `${urlArray[1]}/${fileName}`
                        : `${urlArray[1]}/${urlArray[2]}/${fileName}`;

                    const response = await downloadFileReporting(filePath);
                    const file = new Blob([response.data], { type: "application/pdf" });
                    return { name: fileName, file: file };
                })
            );

            files.forEach(({ name, file }) => {
                zip.file(name, file);
            });

            const content = await zip.generateAsync({ type: "blob" });
            saveAs(content, `${product.productName}-documents.zip`);
        } catch (error) {
            throw error;
        }
    };



    const filterProducts = (products) => {
        if (search === "") {
            return products;
        }

        return products.filter((product) =>
            product.productName.toLowerCase().includes(search.toLowerCase())
        );
    };

    const handleDownload = async (fileRelativePath, fileName) => {
        try {
            const response = await downloadFileReporting(fileRelativePath);
            const file = new Blob([response.data], { type: "application/pdf" });
            saveAs(file, fileName);
        } catch (error) {
            throw error;
        }
    };

    if (!authenticated) {
        return (
            <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <div
                style={{
                    padding: "40px 50px",
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fff",
                    maxWidth: "500px",
                    width: "100%",
                    boxSizing: "border-box",
                }}
            >
                <form
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    onSubmit={handleSubmit}
                >
                    <h2 style={{ marginBottom: "30px" }}>
                        Reportings
                    </h2>
                    <img
                        src={process.env.PUBLIC_URL + "/LOGO-FV.png"}
                        alt="Logo"
                        style={{ maxWidth: "100%", marginBottom: "30px" }}
                    />
                    <>
                        <div
                            style={{
                                position: 'relative',
                                marginBottom: '20px',
                                display: 'inline-block',
                                width: '100%'
                            }}
                        >
                            <input
                                style={{
                                    padding: "15px",
                                    width: "100%",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                    paddingRight: '40px',
                                }}
                                type={showPassword ? "text" : "password"}
                                placeholder="Mot de passe"
                                value={password}
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                    handleInputChange();
                                }}
                                required
                            />
                            <button
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    cursor: 'pointer',
                                    fontSize: '20px',
                                    color: "#aaa"
                                }}
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowPassword(!showPassword);
                                }}
                            >
                                {showPassword ? <FiEye /> : <FiEyeOff /> }
                            </button>
                        </div>
                    </>
                    {errorMessage && (
                        <p style={{ color: "red" }}>{errorMessage}</p>
                    )}
                    <button
                        style={{
                            backgroundColor: "#0066CC",
                            color: "#fff",
                            padding: "15px",
                            borderRadius: "5px",
                            border: "none",
                            cursor: "pointer",
                            width: "100%",
                        }}
                        
                        type="submit"
                        disabled={isLoadingLogin} // Désactiver le bouton pendant le chargement
                    >
                        {isLoadingLogin ? (
                            <span className="spinner-border spinner-border-sm me-2" type="submit" role="status" aria-hidden="true"></span>
                        ) : (
                            "Se connecter"
                        )}
                    </button>
                </form>
            </div>
        </div>
        );
      }

    return (
        <Container>
            {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
            <>
                <Container className="my-5">
                    <h1 className="text-center mb-4" style={{color: '#092A6C'}}>Reportings</h1>
                    <Card className="shadow mb-5">
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <div style={{ height: "640px", width: "550px" }}>
                                        <iframe
                                            title="Google Map 2"
                                            src="https://experience.arcgis.com/experience/2b38cfb810514ecb8553b826cf561a43/"
                                            style={{ height: "100%", width: "100%", border: "none" }}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div style={{ height: "640px", width: "100%" }}>
                                        <iframe
                                            title="Google Map 1"
                                            src="https://www.google.com/maps/d/embed?mid=14s_eByxUN5mzFdMIhJZQA4fL2NgKbtEd&ehbc=2E312F"
                                            style={{ height: "100%", width: "100%", border: "none" }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Tab.Container
                                defaultActiveKey="reportings"
                                onSelect={(tab) => {
                                    setActiveTab(tab);
                                    setSelectedProduct(null);
                                }}
                            >
                                <Row className="mt-3">
                                    <Col sm={12}>
                                        <Nav variant="tabs">
                                            <Nav.Item>
                                                <Nav.Link eventKey="reportings">Reportings</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="communicated">Communiqués</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="assembly">Assemblées</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Row className="mt-3">
                                            <Col sm={12}>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Rechercher un produit"
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="reportings">
                                                <div className="row">
                                                    {filterProducts(uniqueReportings()).map((product) => (
                                                        <div key={product.id} className="col-md-4 col-sm-6">
                                                            <div
                                                                className={`product-card${selectedProduct === product.id ? " selected" : ""}`}
                                                                onClick={() => handleProductSelect(selectedProduct === product.id ? null : product.id)}
                                                            >
                                                                <div className="product-card-header">
                                                                    <span>{product.productName}</span>
                                                                    {selectedProduct === product.id ? <IoIosArrowDown /> : <IoIosArrowForward />}
                                                                </div>
                                                                {selectedProduct === product.id && renderDocuments(reportingsList)}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="communicated">
                                                <div className="row">
                                                    {filterProducts(uniqueCommunicated()).map((product) => (
                                                        <div key={product.id} className="col-md-4 col-sm-6">
                                                            <div
                                                                className={`product-card${selectedProduct === product.id ? " selected" : ""}`}
                                                                onClick={() => handleProductSelect(selectedProduct === product.id ? null : product.id)}
                                                            >
                                                                <div className="product-card-header">
                                                                    <span>{product.productName}</span>
                                                                    {selectedProduct === product.id ? <IoIosArrowDown /> : <IoIosArrowForward />}
                                                                </div>
                                                                {selectedProduct === product.id && renderDocuments(communicatedList)}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="assembly">
                                                <div className="row">
                                                    {filterProducts(uniqueAssembly()).map((product) => (
                                                        <div key={product.id} className="col-md-4 col-sm-6">
                                                            <div
                                                                className={`product-card${selectedProduct === product.id ? " selected" : ""}`}
                                                                onClick={() => handleProductSelect(selectedProduct === product.id ? null : product.id)}
                                                            >
                                                                <div className="product-card-header">
                                                                    <span>{product.productName}</span>
                                                                    {selectedProduct === product.id ? <IoIosArrowDown /> : <IoIosArrowForward />}
                                                                </div>
                                                                {selectedProduct === product.id && renderDocuments(assemblyList)}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </Container>
            </>
            )}
        </Container>
    );

}

export default Reporting;
