import axios from "axios";

export const getFraisExPostByPartner = async () => {
    try {
        const response = await axios.get('https://extranet.france-valley.com/api/fraisExPosts/partner' , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}
