import React, {useState} from "react";
import {editOldPasswordClient, editPasswordClient, editPasswordConseiller} from "../services/Auth";
import {useNavigate} from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";

function ChangeOldPasswordClient() {
    const [codeAssocie, setCodeAssocie] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [showCurrentPassword, setShowCurrentPassword] = useState(false); 
    const [password, setPassword] = useState("");
    const [passwordCheck, setPasswordCheck] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordCheck, setShowPasswordCheck] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();


    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const response = await editOldPasswordClient(codeAssocie, currentPassword, password, passwordCheck);
            if (response.status === 200) {
                setSuccessMessage(response.message);
                setTimeout(() => {
                    setSuccessMessage("");
                    navigate("/dashboard");
                }, 3000);
            } else {
                setErrorMessage(  "Une erreur s'est produite. Veuillez réessayer.");
            }
        } catch (err) {
            setErrorMessage(err.response.data.error || "Une erreur s'est produite. Veuillez réessayer.");
        } finally {
            setIsLoading(false);
        }
    };

    const clearErrorMessage = () => {
        setErrorMessage("");
    };

    const inputStyle = {
        marginBottom: "20px",
        padding: "15px",
        width: "100%",
        borderRadius: "5px",
        border: "1px solid #ccc",
    };

    const eyeButtonStyle = {
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-80%)',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        fontSize: '20px',
        color: "#aaa"
    }

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <div
                    style={{
                        padding: "40px 50px",
                        border: "1px solid #ccc",
                        borderRadius: "10px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        backgroundColor: "#fff",
                        maxWidth: "500px",
                        width: "100%",
                        boxSizing: "border-box",
                    }}
                >
                    <h2 style={{ marginBottom: "30px", textAlign: "center" }}>
                        Changement du mot de passe
                    </h2>
                    <img
                        src={process.env.PUBLIC_URL + "/LOGO-FV.png"}
                        alt="Logo"
                        style={{ maxWidth: "100%", marginBottom: "30px" }}
                    />
                    <form onSubmit={handleSubmit}>
                        {errorMessage && (
                            <p style={{ color: "red", marginBottom: "20px" }}>{errorMessage}</p>
                        )}
                        {successMessage && (
                            <p style={{ color: "green", marginBottom: "20px" }}>{successMessage}</p>
                        )}
                            <input
                                style={inputStyle}
                                type="text"
                                placeholder="Code associé"
                                value={codeAssocie}
                                onChange={(event) => {
                                    setCodeAssocie(event.target.value);
                                    clearErrorMessage()
                                }}
                                required
                            />

                         <div
                            style={{
                                position: 'relative',
                                display: 'inline-block',
                                width: '100%'
                            }}
                        >
                            <input
                                style={{
                                    ...inputStyle,
                                    paddingRight: '40px',
                                }}
                                type={showCurrentPassword ? "text" : "password"}
                                placeholder="Mot de passe actuel"
                                value={currentPassword}
                                onChange={(event) => {
                                    setCurrentPassword(event.target.value);
                                    clearErrorMessage();
                                }}
                                required
                            />
                            <button
                                style={eyeButtonStyle}
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowCurrentPassword(!showCurrentPassword);
                                }}
                            >
                                {showCurrentPassword ?  <FiEye /> : <FiEyeOff />  }
                            </button>
                        </div>
                        <div
                            style={{
                                position: 'relative',
                                display: 'inline-block',
                                width: '100%'
                            }}
                        >
                            <input
                                style={{
                                    ...inputStyle,
                                    paddingRight: '40px',
                                }}
                                type={showPassword ? "text" : "password"}
                                placeholder="Nouveau mot de passe"
                                value={password}
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                    clearErrorMessage();
                                }}
                                required
                            />
                            <button
                                style={eyeButtonStyle}
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowPassword(!showPassword);
                                }}
                            >
                                {showPassword ?  <FiEye /> : <FiEyeOff />  }
                            </button>
                        </div>
                        <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                            <input
                                style={{
                                    ...inputStyle,
                                    paddingRight: '40px', // Ajoutez du padding à droite pour éviter que l'icône ne chevauche le texte
                                }}
                                type={showPasswordCheck ? "text" : "password"}
                                placeholder="Nouveau mot de passe"
                                value={passwordCheck}
                                onChange={(event) => {
                                    setPasswordCheck(event.target.value);
                                    clearErrorMessage();
                                }}
                                required
                            />
                            <button
                                style={eyeButtonStyle}
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowPasswordCheck(!showPasswordCheck);
                                }}
                            >
                                {showPasswordCheck ?  <FiEye /> : <FiEyeOff />}
                            </button>
                        </div>
                        <button
                            style={{
                                backgroundColor: "#0066CC",
                                color: "#fff",
                                padding: "15px",
                                borderRadius: "5px",
                                border: "none",
                                cursor: "pointer",
                                width: "100%",
                            }}
                            type="submit"
                            disabled={isLoading} // Désactiver le bouton pendant le chargement
                        >
                            {isLoading ? (
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            ) : (
                                "Soumettre"
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default ChangeOldPasswordClient;
