import React, {useState} from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import styles from '../css/YearCard.module.css';
import { FaArrowLeft } from 'react-icons/fa';
import { FaArrowRight } from 'react-icons/fa';

function YearCard({ year, taxData, renderClientData, role, handleSearch }) {
    const [showCardBody, setShowCardBody] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");


    const handleYearClick = () => {
        setShowCardBody(!showCardBody);
    };

    const handleInputChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
    
        if (newSearchTerm.trim() === '') {
            handleSearch(newSearchTerm);
        }
    };

    const handleSearchButton = () => {
        if (searchTerm.trim() !== "") {
            handleSearch(searchTerm);
        }
    };

    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            if (searchTerm.trim() !== "") {
                handleSearch(searchTerm);
            }
        }
    }


    return (
        <div className={styles.flexContainer}>
            <Card className={styles.card}>
                <Card.Header className={styles.cardHeader} onClick={() => handleYearClick(year)}>
                    <Button variant="link" className={styles.fullWidth}>
                        Année {year}
                    </Button>
                </Card.Header>
                <Card.Body className={showCardBody ? `${styles.cardBody} ${styles.show}` : styles.cardBody}>
                    {role === 'conseiller' && (
                        <Form.Group controlId="searchForm">
                        <Form.Label>Rechercher par Nom et Prénom</Form.Label>
                        <Form.Control type="text" placeholder="Nom et Prénom"  value={searchTerm} onChange={handleInputChange} onKeyDown={handleKeyDown}/>
                            <Button className="btn-custom" onClick={handleSearchButton}>
                                Rechercher
                            </Button>
                    </Form.Group>
                    )}
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>{role === 'conseiller' ? 'Nom et Prénom' : 'Nom du fond'}</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderClientData(taxData, year)}
                        </tbody>
                    </table>
                </Card.Body>
            </Card>
        </div>
    );
}

export default YearCard;
