import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {loginPartner} from "../services/Auth";
import {FiEye, FiEyeOff} from "react-icons/fi";


function LoginPartner() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const navigate = useNavigate();


    const handleFormPartnerSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const response = await loginPartner({
                email,
                password,
            });

            if (response.token) {
                if (response.changePassword) {
                    navigate("/modifMotDePasseConseiller");
                } else if (response.changeOldPassword) {
                    navigate("/ChangementMotDePasseConseiller");
                } else {
                    navigate("/dashboard");
                }
            } else {
                setErrorMessage(  "Une erreur s'est produite. Veuillez réessayer.");
            }
        } catch (error) {
            setErrorMessage(error.response.data.error || "Une erreur s'est produite. Veuillez réessayer.");
        } finally {
            setIsLoading(false); // Fin du chargement (succès ou échec)
        }
    };

    const handleInputChange = () => {
        setErrorMessage("");
    };


    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <div
                    style={{
                        padding: "40px 50px",
                        border: "1px solid #ccc",
                        borderRadius: "10px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        backgroundColor: "#fff",
                        maxWidth: "500px",
                        width: "100%",
                        boxSizing: "border-box",
                    }}
                >
                    <form
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                        onSubmit={handleFormPartnerSubmit}
                    >
                        <h2 style={{ marginBottom: "30px" }}>
                            Extranet Partenaire
                        </h2>
                        <img
                            src={process.env.PUBLIC_URL + "/LOGO-FV.png"}
                            alt="Logo"
                            style={{ maxWidth: "100%", marginBottom: "30px" }}
                        />
                        <input
                            style={{
                                marginBottom: "20px",
                                padding: "15px",
                                width: "100%",
                                borderRadius: "5px",
                                border: "1px solid #ccc",
                            }}
                            type={"email"}
                            placeholder={"Email"}
                            value={email}
                            onChange={(event) => {
                                setEmail(event.target.value);
                                handleInputChange();
                            }}
                            required
                        />
                        <>
                            <div
                                style={{
                                    position: 'relative',
                                    marginBottom: '20px',
                                    display: 'inline-block',
                                    width: '100%'
                                }}
                            >
                                <input
                                    style={{
                                        padding: "15px",
                                        width: "100%",
                                        borderRadius: "5px",
                                        border: "1px solid #ccc",
                                        paddingRight: '40px',
                                    }}
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Mot de passe"
                                    value={password}
                                    onChange={(event) => {
                                        setPassword(event.target.value);
                                        handleInputChange();
                                    }}
                                    required
                                />
                                <button
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        cursor: 'pointer',
                                        fontSize: '20px',
                                        color: "#aaa"
                                    }}
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowPassword(!showPassword);
                                    }}
                                >
                                    {showPassword ? <FiEye /> : <FiEyeOff /> }
                                </button>
                            </div>
                        </>

                        {errorMessage && (
                            <p style={{ color: "red" }}>{errorMessage}</p>
                        )}
                        <p
                            style={{
                                marginTop: "10px",
                                fontSize: "15px",
                                textAlign: "center",
                            }}
                        >
                            <Link to={'/conseiller/motDePasseOublie'}>
                                Mot de passe oublié ?
                            </Link>
                            {" "}
                            /
                            {" "}
                            <Link to={'/conseiller/motDePasseOublie'}>
                                Première connexion
                            </Link>

                        </p>
                        <button
                            style={{
                                backgroundColor: "#0066CC",
                                color: "#fff",
                                padding: "15px",
                                borderRadius: "5px",
                                border: "none",
                                cursor: "pointer",
                                width: "100%",
                            }}
                            type="submit"
                            disabled={isLoading} // Désactiver le bouton pendant le chargement
                        >
                            {isLoading ? (
                                <span className="spinner-border spinner-border-sm me-2" type="submit" role="status" aria-hidden="true"></span>
                            ) : (
                                "Se connecter"
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default LoginPartner;
