import React, {useState} from "react";
import {forgotPasswordClient, forgotPasswordConseiller} from "../services/Auth";
import { useNavigate } from "react-router-dom";
function ForgotPassword({ type }) {


    const [codeAssocie, setCodeAssocie] = useState("");
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        if (type === "client") {
            try {
                const response = await forgotPasswordClient(codeAssocie, email);
                if (response.status === 200) {
                    setSuccessMessage(response.message);
                    setTimeout(() => {
                        setSuccessMessage("");
                        navigate("/loginClient");
                    }, 3000);
                } else {
                    setErrorMessage("Une erreur s'est produite. Veuillez réessayer.");
                }
            } catch (err) {
                setErrorMessage(err.response.data.error || "Une erreur s'est produite. Veuillez réessayer.");
            } finally {
                setIsLoading(false);
            }
        } else if (type === "conseiller") {
            try {
                const response = await forgotPasswordConseiller(email);
                if (response.status === 200) {
                    setSuccessMessage(response.message);
                    setTimeout(() => {
                        setSuccessMessage("");
                        navigate("/loginPartner");
                    }, 3000);
                } else {
                    setErrorMessage("Une erreur s'est produite. Veuillez réessayer.");
                }
            } catch (err) {
                console.error(err);
                setErrorMessage(err.response.data.error || "Une erreur s'est produite. Veuillez réessayer.");
            } finally {
                setIsLoading(false);
            }
        }

    };

    const clearErrorMessage = () => {
        setErrorMessage("");
    };


    const inputStyle = {
        marginBottom: "20px",
        padding: "15px",
        width: "100%",
        borderRadius: "5px",
        border: "1px solid #ccc",
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <div
                style={{
                    padding: "40px 50px",
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fff",
                    maxWidth: "500px",
                    width: "100%",
                    boxSizing: "border-box",
                }}
            >
                <h2 style={{ marginBottom: "30px", textAlign: "center" }}>
                    {type === "client"
                        ? "Mot de passe oublié"
                        : "Mot de passe oublié"}
                </h2>
                <img
                    src={process.env.PUBLIC_URL + "/LOGO-FV.png"}
                    alt="Logo"
                    style={{ maxWidth: "100%", marginBottom: "30px" }}
                />
                <form onSubmit={handleSubmit}>
                    {errorMessage && (
                        <p style={{ color: "red", marginBottom: "20px" }}>{errorMessage}</p>
                    )}
                    {successMessage && (
                        <p style={{ color: "green", marginBottom: "20px" }}>{successMessage}</p>
                    )}
                    {type === "client" && (
                        <input
                            style={inputStyle}
                            type="text"
                            placeholder="Code associé"
                            value={codeAssocie}
                            onChange={(event) => {
                                setCodeAssocie(event.target.value);
                                clearErrorMessage();
                            }}
                            required
                        />
                    )}
                        <input
                            style={inputStyle}
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(event) => {
                                setEmail(event.target.value);
                                clearErrorMessage();
                            }}
                            required
                        />
                    <button
                        style={{
                            backgroundColor: "#0066CC",
                            color: "#fff",
                            padding: "15px",
                            borderRadius: "5px",
                            border: "none",
                            cursor: "pointer",
                            width: "100%",
                        }}
                        type="submit"
                        disabled={isLoading} // Désactiver le bouton pendant le chargement
                    >
                        {isLoading ? (
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        ) : (
                            "Soumettre"
                        )}
                    </button>
                </form>
            </div>
        </div>
    );
}

export default ForgotPassword;
