import React, { useState } from "react";
import { Accordion, Button, Container } from "react-bootstrap";
import PageContainer from "../components/PageContainer";
import '../css/Faq.css';

function Faq() {

    const faq = [
        {
            question: "Je souhaite resouscrire mais mes informations ont changé, comment les modifier ?",
            answer: "Depuis votre Espace Client, dans l'onglet « REsouscription », cliquez sur le lien « cliquez si des informations de souscription ont changé »."
        },
        {
            question: "Où trouver mon attestation fiscale ?",
            answer: "Vous trouverez votre attestation fiscale depuis votre Espace Client dans l’onglet « mes souscriptions »."
        },
        {
            question: "Où trouver mes bordereaux fiscaux ?",
            answer: "Vous trouverez vos bordereaux sur votre Espace Client dans l’onglet « mes informations fiscales et AG »."
        },
        {
            question: "Où trouver les documents d’Assemblée Générale ?",
            answer: "Vous trouverez ces documents sur votre Espace Client dans l’onglet « mes reportings »."
        },
        {
            question: "Comment puis-je connaître la valeur de rachat de mes participations ?",
            answer: "Vous avez accès à ces informations sur votre Espace Client dans l’onglet « mes souscriptions »."
        },

    ];

    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <PageContainer>
            <Container className="my-5">
                <h1 className="text-center my-2" style={{ color: '#092A6C' }}>FAQ</h1>
                <Accordion className="shadow-lg my-3">
                    {faq.map((item, index) => (
                        <Accordion.Item key={index} eventKey={index.toString()}>
                            <Accordion.Header
                                onClick={() => toggleAccordion(index)}
                                style={{ cursor: 'pointer',  padding: '1rem',  }}
                                className="custom-accordion-header"
                            >
                            <strong>{item.question}</strong>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                                    {index === 8 && (
                                        <Button 
                                            href="/parrainage" 
                                            variant="primary"
                                            className="mt-3"
                                        >
                                            Découvrir notre offre
                                        </Button>
                                    )}
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </Container>
        </PageContainer>
    );
}

export default Faq;