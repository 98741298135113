import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container } from 'react-bootstrap';
import { logout } from "../services/Auth";
import {checkLastSubOnlineForPartner, getPartnerByUserId} from "../services/Partner";
import { getUserById } from "../services/user";
import { getClientByUserId } from "../services/Client";
import '../css/Navbar.css';
import { AiOutlineUser } from "react-icons/ai";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { checkLastSubscribeForClient } from "../services/Subscribe";

function NavbarSite({ navbarRef, navbarPosition }) {

    const [partner, setPartner] = useState({});
    const [user, setUser] = useState({});
    const [client, setClient] = useState({});
    const [partnerSubOnline, setPartnerSubOnline] = useState();
    const [parrainage, setParrainage] = useState(false);

    const handleLogout = async () => {
        try {
            const res = await logout();
            if (res.status === 200) {
                window.location.href = '/';
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        async function getUserLoad() {
            const res = await getUserById();
            setUser(res);
            const role = res.role;
            if (role === "conseiller") {
                const res = await getPartnerByUserId(user.id);
                const checkSubOnline = await checkLastSubOnlineForPartner();
                setPartner(res);
                setPartnerSubOnline(checkSubOnline);
            } else if (role === "client") {
                const res = await getClientByUserId(user.id);
                const resParrainge = await checkLastSubscribeForClient();
                setParrainage(resParrainge.isPartner);
                setClient(res);
            }
        }
        getUserLoad();
    }, []);

    const profileName = user.role === "conseiller" ? partner.name : `${client.firstName ?? ""} ${client.lastName ?? ""}`;

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="d-lg-none navbar-mobile"  style={{ position: navbarPosition}}
                    ref={navbarRef}>
                <Container>
                    <Navbar.Brand href="/dashboard" className="navbar-brand-custom text-center">
                        <img src={process.env.PUBLIC_URL + './Logo FV-blanc.png'} alt="Logo" className="small-logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ marginLeft: "auto", backgroundColor: "#FFFFFF" }} />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">

                            {user.role === "client" && (
                                <>
                                    <Nav.Link href="/dashboard">Tableau de Bord</Nav.Link>
                                    <div className="navbar-separator"></div>
                                    <Nav.Link href="/actualites">Actualités</Nav.Link>
                                    <div className="navbar-separator"></div>
                                    {client.onlineSubscribe > 0 ? (
                                        <>
                                            <Nav.Link href="/reSouscription">REsouscription</Nav.Link>
                                            <div className="navbar-separator"></div>
                                            <Nav.Link href="/souscriptionEnLigne">Souscrire en ligne</Nav.Link>
                                            <div className="navbar-separator"></div>
                                        </>
                                    ) : (
                                        <>
                                            <Nav.Link href="/souscriptionEnLigne">Souscrire en ligne</Nav.Link>
                                            <div className="navbar-separator"></div>
                                        </>

                                    )}
                                    {parrainage === true && (
                                        <>
                                            <Nav.Link href="/parrainage">parrainage</Nav.Link>
                                            <div className="navbar-separator"></div>
                                         </>
                                    )}
                                    <Nav.Link href="/reportings">Mes Reportings</Nav.Link>
                                    <div className="navbar-separator"></div>
                                    <Nav.Link href="/mesSouscriptions">Mes Souscriptions</Nav.Link>
                                    <div className="navbar-separator"></div>
                                    <Nav.Link href="/mesInformationsFiscales">Mes informations fiscales et AG</Nav.Link>
                                    <div className="navbar-separator"></div>
                                    <Nav.Link href="/compte">Mon Compte</Nav.Link>
                                    <div className="navbar-separator"></div>
                                    <Nav.Link href="/FAQ">FAQ</Nav.Link>
                                    <div className="navbar-separator"></div>
                                    <Nav.Link href="/contact">Contact</Nav.Link>
                                    <div className="navbar-separator"></div>
                             
                                </>
                                )}
                                {user.role === "conseiller" && (
                                    <>
                                        <Nav.Link href="/dashboard">Tableau de Bord</Nav.Link>
                                        <div className="navbar-separator"></div>
                                        <Nav.Link href="/actualites">Actualités</Nav.Link>
                                        <div className="navbar-separator"></div>
                                        <Nav.Link href="/souscriptionEnLigne">Souscription en ligne</Nav.Link>
                                        <div className="navbar-separator"></div>
                                        {partnerSubOnline === true && (
                                            <>
                                                <Nav.Link href="/reSouscription">REsouscription</Nav.Link>
                                                <div className="navbar-separator"></div>
                                            </>
                                        )}
                                        <Nav.Link href="/Fonds">Les Fonds</Nav.Link>
                                        <div className="navbar-separator"></div>
                                        <Nav.Link href="/mesSouscriptions">Les Souscriptions</Nav.Link>
                                        <div className="navbar-separator"></div>
                                        <Nav.Link href="/mesInformationsFiscales">Informations fiscales et AG</Nav.Link>
                                        <div className="navbar-separator"></div>
                                        <Nav.Link href="/reportings">Reportings</Nav.Link>
                                        <div className="navbar-separator"></div>
                                        <Nav.Link href="/simulations">Simulations</Nav.Link>
                                        <div className="navbar-separator"></div>
                                        <Nav.Link href="/commissions">Commissions</Nav.Link>
                                        <div className="navbar-separator"></div>
                                        <Nav.Link href="/fraisExPost">Frais Ex Post</Nav.Link>
                                        <div className="navbar-separator"></div>
                                        <Nav.Link href="/mesCollaborateurs">Collaborateurs</Nav.Link>
                                        <div className="navbar-separator"></div>
                                        <Nav.Link href="/compte">Mon compte</Nav.Link>
                                        <div className="navbar-separator"></div>
                                        <Nav.Link href="/contact">Contact</Nav.Link>
                                        <div className="navbar-separator"></div>
                                    </>
                                    )}
                            <Nav.Link onClick={handleLogout}>
                                <RiLogoutBoxRLine size={20} />
                                Déconnexion
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <div className="sidebar d-none d-lg-block">
                <div className="sidebar-brand">
                    <a href="/dashboard">
                        <img src={process.env.PUBLIC_URL + './Logo FV-blanc.png'} alt="Logo" style={{ maxWidth: '110%' }} />
                    </a>
                </div>
                <div className="sidebar-content">
                    <div className="profile-section" style={{color: '#ffffff' }}>
                        <AiOutlineUser size={30} />
                        <p className="text-center">{profileName}</p>
                    </div>
                    <Nav className="sidebar-nav d-flex flex-column">
                        {user.role === "client" && (
                            <>
                                <Nav.Link href="/dashboard">Tableau de Bord</Nav.Link>
                                <Nav.Link href="/actualites">Actualités</Nav.Link>
                                {client.onlineSubscribe > 0 ? (
                                    <>
                                        <Nav.Link href="/reSouscription">REsouscription</Nav.Link>
                                        <Nav.Link href="/souscriptionEnLigne">Souscrire en ligne</Nav.Link>
                                    </>
                                
                                ) : (
                                    <Nav.Link href="/souscriptionEnLigne">Souscrire en ligne</Nav.Link>
                                )}
                                {parrainage === true && (
                                     <Nav.Link href="/parrainage">Parrainage</Nav.Link>
                                )}
                                <Nav.Link href="/reportings">Mes Reportings</Nav.Link>
                                <Nav.Link href="/mesSouscriptions">Mes Souscriptions</Nav.Link>
                                <Nav.Link href="/mesInformationsFiscales">Mes Informations Fiscales et AG</Nav.Link>
                                <Nav.Link href="/compte">Mon Compte</Nav.Link>
                                <Nav.Link href="/FAQ">FAQ</Nav.Link>
                                <Nav.Link href="/contact">Contact</Nav.Link>
                            </>
                            )}

                        {user.role === "conseiller" && (
                            <>
                                <Nav.Link href="/dashboard">Tableau de Bord</Nav.Link>
                                <Nav.Link href="/actualites">Actualités</Nav.Link>
                                <Nav.Link href="/souscriptionEnLigne">Souscription en ligne</Nav.Link>
                                {partnerSubOnline === true && (
                                    <Nav.Link href="/reSouscription">REsouscription</Nav.Link>
                                )}
                                <Nav.Link href="/Fonds"> Les Fonds</Nav.Link>
                                <Nav.Link href="/mesSouscriptions">Les Souscriptions</Nav.Link>
                                <Nav.Link href="/mesInformationsFiscales">Informations Fiscales et AG</Nav.Link>
                                <Nav.Link href="/reportings">Reportings</Nav.Link>
                                <Nav.Link href="/simulations">Simulations</Nav.Link>
                                <Nav.Link href="/commissions">Commissions</Nav.Link>
                                <Nav.Link href="/fraisExPost">Frais Ex Post</Nav.Link>
                                <Nav.Link href="/mesCollaborateurs">Collaborateurs</Nav.Link>
                                <Nav.Link href="/compte">Mon Compte</Nav.Link>
                                <Nav.Link href="/contact">Contact</Nav.Link>
                            </>
                        )}
                        <Nav.Link onClick={handleLogout}>
                            <RiLogoutBoxRLine size={20} />
                            Déconnexion
                        </Nav.Link>
                    </Nav>
                </div>
            </div>
        </>
    );
}

export default NavbarSite;
