import axios from 'axios';

export const getClientByUserId = async () => {
    try {
        const response = await axios.get('https://dev.france-valley.paris/api/clients/user/userId' , { withCredentials: true });
        return response.data;
    } catch (error) {
        console.error(error);
    }
}
/*export const checkClientBySubOnline = async () => {
    try {
        const response = await axios.get("http://127.0.0.1:3001/api/clients/checkSubOnline" , { withCredentials: true });
        return response.data;
    } catch (error) {
        console.error(error);
    }
}*/
export const sendContactForm = async (formData) => {
    try {
        const response = await axios.post(
            "https://dev.france-valley.paris/api/clients/contact",
            formData,
            {
                withCredentials: true,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return response;
    } catch (error) {
        throw error;
    }
};
