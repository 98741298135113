import React, {useEffect, useState} from "react";
import {Button, Card, Container, Table} from "react-bootstrap";
import {getConseillersByPartner} from "../services/Conseiller";
import Spinner from "react-bootstrap/Spinner";
import PageContainer from "../components/PageContainer";
import '../css/MesCollaborateurs.css';


function MesCollaborateurs() {

    const [conseillers, setConseillers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        async function getUserLoad() {
            const conseillers = await getConseillersByPartner();
            setConseillers(conseillers);
            setIsLoading(false);
        }
        getUserLoad();
    }, []);



    return (
        <PageContainer isLoading={isLoading}>
            {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <>
                    <Container className="my-5">
                    <h1 className="text-center my-2" style={{color: '#092A6C'}}>Liste de mes Collaborateurs</h1>
                    <Card className="shadow-lg my-3">
                        <Card.Body>
                            <div className="text-center mt-2 p-4 rounded border border-primary bg-light ">
                                <h4 className="mb-3 " style={{color: '#092A6C'}}>Modification des informations personnelles</h4>
                                <p>
                                    Pour des raisons de sécurité et de traçabilité, il n'est pas possible de modifier en ligne les informations de vos Collaborateurs ou d'ajouter des Collaborateurs.
                                </p>
                                <p>
                                    Vous pouvez néanmoins effectuer une demande avec le formulaire ci-dessous :
                                    <br />
                                    -  Modifier les informations d'un Collaborateur
                                    <br />
                                    -  Ajouter un Collaborateur
                                </p>
                                <a href={`mailto:backoffice@france-valley.com?subject=Extranet FRANCE VALLEY - Demande de modification - Mes Collaborateurs&body=${encodeURIComponent('Bonjour,\n\nNous vous remercions de prendre en compte la modification suivante concernant nos Collaborateurs :')}`}>
                                    <Button className="btn-custom mt-3">
                                        Cliquez ici pour faire la demande de modification
                                    </Button>
                                </a>
                            </div>
                            <br/>
                        </Card.Body>
                        <Card.Body>
                            <Card.Title className="mb-4 text-center" style={{color: '#224A96'}}>Mes Collaborateurs</Card.Title>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="table-responsive">
                                        <Table className="table table-striped table-bordered table-hover table-sm">
                                            <thead>
                                            <tr>
                                                <th>Nom</th>
                                                <th>Prénom</th>
                                                <th>Email</th>
                                                <th>Téléphone</th>
                                                <th>Poste</th>
                                                <th>Envoi des attestations</th>
                                                <th>Envoi du commissionnement</th>
                                                <th>Envoi des e-maillings</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {conseillers.map((conseiller) => (
                                                <tr key={conseiller.id}>
                                                    <td>{conseiller.lastName}</td>
                                                    <td>{conseiller.firstName}</td>
                                                    <td>{conseiller.email}</td>
                                                    <td>{conseiller.phoneNumber}</td>
                                                    <td>{conseiller.position}</td>
                                                    <td>{conseiller.certificate ? "Oui" : "Non"}</td>
                                                    <td>{conseiller.commission ? "Oui" : "Non"}</td>
                                                    <td>{conseiller.mailling ? "Oui" : "Non"}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Container>
            </>
            )}
        </PageContainer>
    );

}

export default MesCollaborateurs;
