import axios from "axios";

export const loginPartner = async (data) => {

    try {
        const response = await axios.post('https://dev.france-valley.paris/api/users/loginConseiller', data, {
            withCredentials: true,
            } );
        const token = response.data.token;
        //Cookies.set('accessToken', token, { sameSite: 'none', secure: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const loginClient = async (data) => {

    try {
        const response = await axios.post('https://dev.france-valley.paris/api/users/loginClient', data, {
            withCredentials: true,
        } );
        const token = response.data.token;
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const logout = async () => {
    try {
        const res = await axios.post('https://dev.france-valley.paris/api/users/logout', {}, {
            withCredentials: true,
        },)
        return res.data;
    } catch (err) {
        return err
    }
};

export const forgotPasswordClient = async (codeAssocie, email) => {
    try {
        const res = await axios.post('https://dev.france-valley.paris/api/users/forgotPasswordClient', { codeAssocie, email });
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const forgotPasswordConseiller = async (email) => {
    try {
        const res = await axios.post('https://dev.france-valley.paris/api/users/forgotPasswordConseiller', { email });
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const editPasswordClient = async (codeAssocie, currentPassword, password, passwordCheck) => {
    try {
        const res = await axios.post('https://dev.france-valley.paris/api/users/editPasswordClient', { codeAssocie, currentPassword, password, passwordCheck }, {
            withCredentials: true,
        });
        const token = res.data.token;
        //Cookies.set('accessToken', token, { sameSite: 'none', secure: true });
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const editPasswordConseiller = async (email, currentPassword, password, passwordCheck) => {
    try {
        const res = await axios.post('https://dev.france-valley.paris/api/users/editPasswordConseiller', { email, currentPassword, password, passwordCheck }, {
            withCredentials: true,
        });
        const token = res.data.token;
        //Cookies.set('accessToken', token, { sameSite: 'none', secure: true });
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const changePassword = async (currentPassword, newPassword, newPasswordCheck) => {
    try {
        const res = await axios.post('https://dev.france-valley.paris/api/users/changePassword', { currentPassword, newPassword, newPasswordCheck }, {
            withCredentials: true,
        }) ;
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const editOldPasswordConseiller = async (email, currentPassword, password, passwordCheck) => {
    try {
        const res = await axios.post('https://dev.france-valley.paris/api/users/editOldPasswordConseiller', { email, currentPassword, password, passwordCheck }, {
            withCredentials: true,
        });
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const editOldPasswordClient = async (codeAssocie, currentPassword, password, passwordCheck) => {
    try {
        const res = await axios.post('https://dev.france-valley.paris/api/users/editOldPasswordClient', { codeAssocie, currentPassword, password, passwordCheck }, {
            withCredentials: true,
        });
        return res.data;
    } catch (err) {
        throw err;
    }
};