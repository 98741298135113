import React, { useEffect, useState } from "react";
import {Button, Card, Container, Dropdown, DropdownButton, Tab, Table} from "react-bootstrap";
import {
    getSubscribeByPartnerId,
    getSubscribeByClientId,
    getStatsSubscribes, getSubscribeEndDateByPartnerId, getSubscribeEndDateByClientId, getSearchClient
} from "../services/Subscribe";
import {getClientByUserId} from "../services/Client";
import {getUserById} from "../services/user";
import {getPartnerByUserId} from "../services/Partner";
import Spinner from "react-bootstrap/Spinner";
import {
    exportSubscribesByPartner,
    exportSubscribesClient
} from "../services/export";
import { saveAs } from "file-saver";
import { FaArrowLeft } from 'react-icons/fa';
import { FaArrowRight } from 'react-icons/fa';
import formatDate from "../components/FormatDate";
import PageContainer from "../components/PageContainer";
import {downloadFile} from "../services/Product";

import "../css/MesSouscriptions.css";


function MesSouscriptions() {
    const [subscribes, setSubscribes] = useState([]);
    const [subscribesEndDate, setSubscribesEndDate] = useState([]);
    const [numSubscribes, setNumSubscribes] = useState(0);
    const [totalNetSubscription, setTotalNetSubscription] = useState(0);
    const [yearSubscribes, setYearSubscribes] = useState([]);
    const [role, setRole] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [hasSearched, setHasSearched] = useState(false);
    const [searchResults, setSearchResults] = useState([]);



    const [currentPageTabSub, setCurrentPageTabSub] = useState(0);
    const [totalPagesTabSub, setTotalPagesTabSub] = useState(0);
    const [currentPageTabSubEndDate, setCurrentPageTabSubEndDate] = useState(0);
    const [totalPagesTabSubEndDate, setTotalPagesTabSubEndDate] = useState(0);
    const itemsPerPage = 20;


    // Récupération des souscriptions actives et affichage des stats
    useEffect(() => {
        async function loadActiveSubscriptions() {
            try {
                const user = await getUserById();
                setRole(user.role);

                const statsSubscribes = await getStatsSubscribes();
                setNumSubscribes(statsSubscribes.numSubscribes);
                setTotalNetSubscription(statsSubscribes.totalNetSubscription);
                setYearSubscribes(statsSubscribes.yearSubscribes);

                let clientId;
                let partnerId;

                if (user.role === "client") {
                    const client = await getClientByUserId();
                    clientId = client.id;
                } else if (user.role === "conseiller") {
                    const partner = await getPartnerByUserId();
                    partnerId = partner.id;
                }

                let res;
                if (user.role === "conseiller") {
                     res = await getSubscribeByPartnerId(partnerId, itemsPerPage, itemsPerPage * currentPageTabSub);
                     
                     setTotalPagesTabSub(Math.ceil(res.total / itemsPerPage));
                } else if (user.role === "client") {
                     res = await getSubscribeByClientId(clientId, itemsPerPage, itemsPerPage * currentPageTabSub);

                     setTotalPagesTabSub(Math.ceil(res.total / itemsPerPage));
                }
                setSubscribes(res.data);
                setIsLoading(false);
            } catch (error) {
                throw error;
            }
        }
        loadActiveSubscriptions();
    }, [currentPageTabSub]);

    // Récupération des souscriptions terminées
    useEffect(() => {
        async function loadEndedSubscriptions() {
            try {
                const user = await getUserById();
                let partnerId;
                let clientId;

                if (user.role === "client") {
                    const client = await getClientByUserId();
                    clientId = client.id;
                } else if (user.role === "conseiller") {
                    const partner = await getPartnerByUserId();
                    partnerId = partner.id;
                }

                let resSubEndDate;
                if (user.role === "conseiller") {

                    resSubEndDate = await getSubscribeEndDateByPartnerId(partnerId, itemsPerPage, itemsPerPage * currentPageTabSubEndDate);
                    setTotalPagesTabSubEndDate(Math.ceil(resSubEndDate.total / itemsPerPage));
                } else if (user.role === "client") {
                    resSubEndDate = await getSubscribeEndDateByClientId(clientId, itemsPerPage, itemsPerPage * currentPageTabSubEndDate);
                    setTotalPagesTabSubEndDate(Math.ceil(resSubEndDate.total / itemsPerPage));
                }
                setSubscribesEndDate(resSubEndDate.data);
                setIsLoading(false);
            } catch (error) {
                throw error;
            }
        }
        loadEndedSubscriptions();
    }, [currentPageTabSubEndDate]);

    // fonction pour exporter les souscriptions
    async function handleExportClient(format) {
        await exportSubscribesClient(format);
    }


    async function handleExportConseillerAll(format) {
        await exportSubscribesByPartner(format, searchTerm);
    }


    const searchSubscriptions = async () => {
        if (searchTerm && searchTerm.trim() !== '') {
            const results = await getSearchClient(searchTerm);
            setSearchResults(results);
            setHasSearched(true);
        } else {
            setSearchResults([]); // Effacer les résultats de la recherche si le terme de recherche est vide
            setHasSearched(false);
        }
    };

    function handleSearch(event) {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
        if (!newSearchTerm || newSearchTerm.trim() === '') {
            setSearchResults([]);
            setHasSearched(false);
        }
    }

    function formatNumber(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            searchSubscriptions();
        }
    }

    const handleDownloadFile = (filePath) => {
        const urlArray = filePath.split("/");
        const numberOfSubfolders = urlArray.length - 2;
        const fileName = urlArray[urlArray.length - 1];
        const finalPath = numberOfSubfolders === 1
            ? `${urlArray[1]}/${fileName}`
            : `${urlArray[1]}/${urlArray[2]}/${fileName}`;

        handleDownload(finalPath, fileName);
    };


    const handleDownload = async (fileRelativePath, fileName) => {
        try {
            const response = await downloadFile(fileRelativePath);
            const file = new Blob([response.data], { type: "application/pdf" });
            saveAs(file, fileName);
        } catch (error) {
            console.log(error);
        }
    };


    return (
       <PageContainer isLoading={isLoading}>
            {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <>
                    <Container className="my-5">
                        <h1 className="text-center mb-5" style={{color: '#092A6C'}}>{role === "conseiller" ? "Les Souscriptions" : "Mes Souscriptions"}</h1>
                        <Card className="shadow mb-5">
                            <Card.Body>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h3 style={{color: '#092A6C'}}>Mes statistiques clés</h3>
                                        <p>Nombre de souscriptions: {numSubscribes.toLocaleString('fr-FR')}</p>
                                        <p>Total des souscriptions nettes: {totalNetSubscription.toLocaleString('fr-FR')}€</p>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <h3 className=" mb-4" style={{color: '#092A6C', marginTop: "30px"}}>Souscriptions par année</h3>
                        <Card className="mb-4 shadow">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="table-responsive">
                                            <Table className="table table-striped table-bordered table-hover table-sm m-0">
                                                <thead>
                                                <tr className="text-center">
                                                    <th>Année</th>
                                                    {yearSubscribes.map((yearSub) => (
                                                        <th key={yearSub.year}>{yearSub.year}</th>
                                                    ))}
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <th>Total des souscriptions nettes</th>
                                                    {yearSubscribes.map((yearSub) => (
                                                        <td key={yearSub.year} className="text-center">{yearSub.total.toLocaleString('fr-FR')}€</td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <th>Souscription moyenne nette</th>
                                                    {yearSubscribes.map((yearSub) => (
                                                        <td key={yearSub.year} className="text-center">{yearSub.average.toLocaleString('fr-FR')}€</td>
                                                    ))}
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                        </Card>                        

                        {role === "conseiller" && (
                            <div className="mb-3">
                                <label htmlFor="searchClient" className="form-label">Rechercher par nom et prénom :</label>
                                <div className="d-flex">
                                    <input
                                        type="text"
                                        id="searchClient"
                                        className="form-control"
                                        placeholder="Rechercher..."
                                        value={searchTerm}
                                        onChange={handleSearch}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <Button
                                        className="btn-custom ml-2"
                                        onClick={searchSubscriptions}
                                    >
                                        Rechercher
                                    </Button>
                                </div>
                            </div>
                        )}
                            <Dropdown>
                                <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="btn-custom"
                                >
                                    Exporter les données
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {role === "conseiller" && (
                                    <>
                                        <Dropdown.Item onClick={() => handleExportConseillerAll("pdf", searchTerm)}>
                                        PDF
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleExportConseillerAll("xlsx", searchTerm)}>
                                        Excel
                                        </Dropdown.Item>
                                    </>
                                    )}
                                    {role === "client" && (
                                    <>
                                        <Dropdown.Item onClick={() => handleExportClient("pdf")}>PDF</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleExportClient("xlsx")}>Excel</Dropdown.Item>
                                    </>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>

                           
                            <Card className="shadow">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first" className="shadow">
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                    <div className="row">
                                            <div className="col-sm-12">
                                                <div className="table-responsive">
                                                    <Table className="table table-striped table-bordered table-hover table-sm m-0">
                                                        <thead>
                                                        <tr className="text-center">
                                                            {role === "conseiller" && (
                                                                <>
                                                                    <th>Nom</th>
                                                                    <th>Prénom</th>
                                                                </>
                                                            )}
                                                            <th>Produit</th>
                                                            <th>Siret</th>
                                                            <th>Date de souscription</th>
                                                            <th>Souscription nette</th>
                                                            <th>Souscription brute</th>
                                                            <th>Nombre de parts</th>
                                                            <th>Prix de souscription actuel</th>
                                                            <th>Valeur de retrait</th>
                                                            <th>Attestation</th>
                                                            <th>B/S</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {hasSearched ? (
                                                            searchResults && searchResults.map((client) => (
                                                                client.subscribes.map((subscribe) => (
                                                                    <tr key={subscribe.id} className="align-middle">

                                                                    {role === "conseiller" && (
                                                                        <>
                                                                            <td className="text-center">
                                                                            {client.lastName ? `${client.lastName}` : "Nom non trouvé"}
                                                                            </td>

                                                                            <td className="text-center">
                                                                            {client.firstName ? `${client.firstName}` : "Prénom non trouvé"}
                                                                            </td>

                                                                        </>
                                                                    )}
                                                                        <td className="text-center">{subscribe.nameGroupement}</td>
                                                                        <td className="text-center">{subscribe.siret}</td>
                                                                        <td className="text-center">{formatDate(subscribe.subscriptionDate)}</td>
                                                                        <td className="text-center">{formatNumber(parseFloat(subscribe.netSubscription).toFixed(2).replace('.', ','))}€</td>
                                                                        <td className="text-center">{formatNumber(parseFloat(subscribe.grossSubscription).toFixed(2).replace('.', ','))}€</td>
                                                                        <td className="text-center">{subscribe.shareNumber}</td>
                                                                        <td className="text-center">{formatNumber(parseFloat(subscribe.subscriptionPrice).toFixed(2).replace('.', ','))}€</td>
                                                                        <td className="text-center">{formatNumber(parseFloat(subscribe.withdrawalValue).toFixed(2).replace('.', ','))}€</td>
                                                                        <td className="text-center">
                                                                            {subscribe.pathAttestation === "en cours" || subscribe.pathAttestation === "sur demande"
                                                                                ? subscribe.pathAttestation
                                                                                : (
                                                                                    <div>
                                                                                        <Button className="btn-custom" onClick={() => handleDownloadFile(subscribe.pathAttestation)}>
                                                                                            Télécharger
                                                                                        </Button>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {subscribe.pathBS === "en cours" || subscribe.pathBS === "sur demande"
                                                                                ? subscribe.pathBS
                                                                                : (
                                                                                    <div>
                                                                                        <Button className="btn-custom" onClick={() => handleDownloadFile(subscribe.pathBS)}>
                                                                                            Télécharger
                                                                                        </Button>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ))
                                                        ) : (
                                                        subscribes && subscribes.length > 0 ? (
                                                                subscribes.map((subscribe) => (
                                                                <tr key={subscribe.id} className="align-middle">

                                                                    {role === "conseiller" && (
                                                                        <>
                                                                            <td className="text-center">
                                                                            {subscribe.client.lastName ? subscribe.client.lastName : "Nom non trouvé"}
                                                                            </td>

                                                                            <td className="text-center">
                                                                                {subscribe.client.firstName ? subscribe.client.firstName : "Prénom non trouvé"}
                                                                            </td>

                                                                        </>
                                                                    )}
                                                                    <td className="text-center">{subscribe.nameGroupement}</td>
                                                                    <td className="text-center">{subscribe.siret}</td>
                                                                    <td className="text-center">{formatDate(subscribe.subscriptionDate)}</td>
                                                                    <td className="text-center">{formatNumber(parseFloat(subscribe.netSubscription).toFixed(2).replace('.', ','))}€</td>
                                                                    <td className="text-center">{formatNumber(parseFloat(subscribe.grossSubscription).toFixed(2).replace('.', ','))}€</td>
                                                                    <td className="text-center">{subscribe.shareNumber}</td>
                                                                    <td className="text-center">{formatNumber(parseFloat(subscribe.subscriptionPrice).toFixed(2).replace('.', ','))}€</td>
                                                                    <td className="text-center">{formatNumber(parseFloat(subscribe.withdrawalValue).toFixed(2).replace('.', ','))}€</td>
                                                                    <td className="text-center">
                                                                        {subscribe.pathAttestation === "en cours" || subscribe.pathAttestation === "sur demande"
                                                                            ? subscribe.pathAttestation
                                                                            : (
                                                                                <div>
                                                                                    <Button className="btn-custom" onClick={() => handleDownloadFile(subscribe.pathAttestation)}>
                                                                                        Télécharger
                                                                                    </Button>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {subscribe.pathBS === "en cours" || subscribe.pathBS === "sur demande"
                                                                            ? subscribe.pathBS
                                                                            : (
                                                                                <div>
                                                                                    <Button className="btn-custom" onClick={() => handleDownloadFile(subscribe.pathBS)}>
                                                                                        Télécharger
                                                                                    </Button>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="6" className="text-center">
                                                                    Vous n'avez pas de souscription.
                                                                </td>
                                                            </tr>
                                                        )
                                                        )}
                                                        </tbody>
                                                    </Table>
                                                    </div>
                                                </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                            </Card>
                            <div className="d-flex justify-content-between mt-2">
                                <Button
                                    className="btn-custom"
                                    onClick={() => setCurrentPageTabSub(currentPageTabSub - 1)}
                                    disabled={currentPageTabSub === 0 || subscribes.length === 0 || hasSearched}
                                >
                                    <FaArrowLeft /> Page précédente
                                </Button>
                                <Button
                                    className="btn-custom"
                                    onClick={() => setCurrentPageTabSub(currentPageTabSub + 1)}
                                    disabled={currentPageTabSub === totalPagesTabSub - 1 || subscribes.length < 20 || hasSearched}
                                >
                                    Page suivante <FaArrowRight />
                                </Button>
                            </div>
                        
                        {subscribesEndDate && subscribesEndDate.length > 0 ? (
                            <>
                            <h3 className=" mb-4" style={{color: '#092A6C', marginTop: "30px"}}>Souscriptions terminées</h3>
                             <Card className="shadow">             
                                <div className="smaller-table-container">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="second">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="second">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="table-responsive">
                                                            <Table className="table table-striped table-bordered table-hover table-sm m-0">
                                                                <thead>
                                                                <tr className="text-center">
                                                                    {role === "conseiller" && (
                                                                        <>
                                                                            <th>Nom</th>
                                                                            <th>Prénom</th>
                                                                        </>
                                                                    )}
                                                                    <th>Produit</th>
                                                                    <th>Siret</th>
                                                                    <th>Date de souscription</th>
                                                                    <th>Date de fin de souscription</th>
                                                                    <th>Souscription nette</th>
                                                                    <th>Souscription brute</th>
                                                                     <th>Nombre de parts</th>
                                                                    <th>Prix de souscription actuel</th>
                                                                    <th>Valeur de retrait</th>
                                                                    <th>Attestation</th>
                                                                    <th>B/S</th>

                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {subscribesEndDate.map((subscribe) => (
                                                                        <tr key={subscribe.id} className="align-middle">

                                                                            {role === "conseiller" && (
                                                                               <>
                                                                               <td className="text-center">
                                                                               {subscribe.client.lastName ? subscribe.client.lastName : "Nom non trouvé"}
                                                                               </td>
       
                                                                               <td className="text-center">
                                                                                   {subscribe.client.firstName ? subscribe.client.firstName : "Prénom non trouvé"}
                                                                               </td>
       
                                                                           </>
                                                                            )}
                                                                            <td className="text-center">{subscribe.nameGroupement}</td>
                                                                            <td className="text-center">{subscribe.siret}</td>
                                                                            <td className="text-center">{formatDate(subscribe.subscriptionDate)}</td>
                                                                            <td className="text-center">{formatDate(subscribe.subscriptionEndDate)}</td>
                                                                            <td className="text-center">{formatNumber(parseFloat(subscribe.netSubscription).toFixed(2).replace('.', ','))}€</td>
                                                                            <td className="text-center">{formatNumber(parseFloat(subscribe.grossSubscription).toFixed(2).replace('.', ','))}€</td>
                                                                            <td className="text-center">{subscribe.shareNumber}</td>
                                                                            <td className="text-center">{formatNumber(parseFloat(subscribe.subscriptionPrice).toFixed(2).replace('.', ','))}€</td>
                                                                            <td className="text-center">{formatNumber(parseFloat(subscribe.withdrawalValue).toFixed(2).replace('.', ','))}€</td>
                                                                            <td className="text-center">
                                                                                {subscribe.pathAttestation === "en cours" || subscribe.pathAttestation === "sur demande"
                                                                                    ? subscribe.pathAttestation
                                                                                    : (
                                                                                        <div>
                                                                                            <Button className="btn-custom" onClick={() => handleDownloadFile(subscribe.pathAttestation)}>
                                                                                                Télécharger
                                                                                            </Button>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {subscribe.pathBS === "en cours" || subscribe.pathBS === "sur demande"
                                                                                    ? subscribe.pathBS
                                                                                    : (
                                                                                        <div>
                                                                                            <Button className="btn-custom" onClick={() => handleDownloadFile(subscribe.pathBS)}>
                                                                                                Télécharger
                                                                                            </Button>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </Card>
                            <div className="d-flex justify-content-between mt-2">
                                <Button
                                    className="btn-custom"
                                    onClick={() => setCurrentPageTabSubEndDate(currentPageTabSubEndDate - 1)}
                                    disabled={currentPageTabSubEndDate === 0 || subscribesEndDate.length === 0}
                                >
                                    <FaArrowLeft /> Page précédente
                                </Button>
                                <Button
                                    className="btn-custom"
                                    onClick={() => setCurrentPageTabSubEndDate(currentPageTabSubEndDate + 1)}
                                    disabled={currentPageTabSubEndDate === totalPagesTabSubEndDate - 1 || subscribes.length < 20}
                                >
                                    Page suivante <FaArrowRight />
                                </Button>
                            </div>
                        </>
                            ) : null}
                    </Container>
                </>
            )}
        </PageContainer>
    );
}

export default MesSouscriptions;
