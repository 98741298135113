import React, {useEffect, useRef, useState} from "react";
import NavbarSite from "./NavbarSite";
import Footer from "./Footer";

function PageContainer({ children, isLoading }) {
    const [navbarWidth, setNavbarWidth] = useState("13rem");
    const [navbarPosition, setNavbarPosition] = useState("left");
    const [footerStyle, setFooterStyle] = useState({});
    const navbarRef = useRef(null);

    useEffect(() => {
        const match = window.matchMedia("(max-width: 991px)");

        function handleResize() {
            if (match.matches) {
                setNavbarWidth("0");
                setNavbarPosition("top");
                setFooterStyle({ marginLeft: 0 });
            } else {
                setNavbarWidth("13em");
                setNavbarPosition("left");
                setFooterStyle({ marginLeft: "13em" });
            }
        }

        handleResize(); // Ajout pour ajuster la taille au chargement de la page
        match.addEventListener("change", handleResize);
        return () => {
            match.removeEventListener("change", handleResize);
        };
    }, []);


    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
                backgroundColor: "#f8f9fa" // Ajustement pour occuper tout l'espace vertical
            }}
        >
            <NavbarSite navbarRef={navbarRef} />

            <div
                style={{
                    marginLeft: navbarWidth,
                    flex: "1", // Ajustement pour occuper tout l'espace restant
                    overflowX: "hidden", // Ajustement pour éviter le scroll horizontal
                }}
            >
                {React.Children.map(children, (child) => {
                    return React.cloneElement(child );
                })}
            </div>
            {!isLoading && <Footer style={footerStyle} />}
        </div>
    );
}

export default PageContainer;

