import React from "react";
import ChangePassword from "../components/ChangePassword";


function ChangePasswordConseiller() {

    return (
        <>
            <ChangePassword type="conseiller" />
        </>
    );
}

export default ChangePasswordConseiller;
