import axios from "axios";

export const getCommissionByPartnerId = async (partnerId) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/commissions/partner/${partnerId}` , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getLastCommission = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/commissions/last" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}
