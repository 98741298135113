import axios from 'axios';

export const getArticles = async () => {
    try {
        const response = await axios.get('https://dev.france-valley.paris/api/articles', { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}
export const getLastArticles = async () => {
    try {
        const response = await axios.get('https://dev.france-valley.paris/api/articles/recent', { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}
