import React from "react";
import ForgotPassword from "../components/ForgotPassword";
function ForgotPasswordClient() {


    return (
        <>
            <ForgotPassword type="client" />
        </>
    );
}

export default ForgotPasswordClient;
