import axios from "axios";

export const getContactsByRole = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/contacts/role" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getContactClient = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/contacts/client" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}
