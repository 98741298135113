import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../css/Footer.css';

function Footer({style}) {
    return (
        <footer className="footer" style={style}>
            <Container>
                <Row>
                    <Col md={4} className="footer-links">
                        <ul>
                            <li><a href="https://www.france-valley.com/app/uploads/2023/12/MENTIONS-LEGALES.pdf"  target="_blank">Mentions légales</a></li>
                            <li><a href="https://www.france-valley.com/app/uploads/2023/12/INFORMATIONS-REGLEMENTAIRES-1.pdf"  target="_blank">Informations réglementaires</a></li>
                        </ul>
                    </Col>
                    <Col md={4} className="footer-links">
                         <ul>
                            <li><a href="https://www.france-valley.com/app/uploads/2023/12/POLITIQUE-DE-COOKIES.pdf"  target="_blank">Politique de cookies</a></li>
                            <li><a href="https://www.france-valley.com/app/uploads/2023/12/CONDITIONS-GENERALES-D-UTILISATION-DU-SITE.pdf"  target="_blank">Conditions générales du site</a></li>
                        </ul>
                    </Col>

                    <Col md={4} className="footer-links">
                        <ul>
                            <li><a href="https://www.france-valley.com/app/uploads/2023/12/POLITIQUE-DE-PROTECTION-DES-DONNEES-PERSONNELLES.pdf"  target="_blank">Politique de confidentialité</a></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col xs={11} className="footer-copy">
                        <p>&copy; 2024 France Valley. Tous droits réservés.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
