import React, { useEffect, useState} from "react";
import {Container, Row, Col, Card, Image} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { saveAs } from "file-saver";
import '../css/Dashboard.css';
import Chart from "../components/Chart";
import {getLastArticles} from "../services/Article";
import {downloadFile, getProductsByLastReportings} from "../services/Product";
import {faFileArrowDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {checkLastSubscribeForClient, getStatsSubscribes} from "../services/Subscribe";
import PageContainer from "../components/PageContainer";
import {getLastCommission} from "../services/Commission";
import formatDate from "../components/FormatDate";
import {getUserById} from "../services/user";
import { FaEnvelope, FaMobileAlt, FaPhone, FaGlassCheers } from "react-icons/fa";
import { getContactClient } from "../services/Contact";
import { Link } from "react-router-dom";




function Dashboard() {
    const [isLoading, setIsLoading] = useState(true);
    const [commission, setCommission] = useState(null);
    const [numSubscribes, setNumSubscribes] = useState(null);
    const [totalNetSubscription, setTotalNetSubscription] = useState(null);
    const [articles, setArticles] = useState([]);
    const [reporting, setReporting] = useState([]);
    const [role, setRole] = useState(null);
    const [contactClient, setContactClient] = useState(null);
    const [parrainage, setParrainage] = useState(false);

    useEffect(() => {
        async function loadData() {

            const res = await getUserById();
            setRole(res.role);
            if (role === "conseiller") {
                const lastCommission = await getLastCommission();
                setCommission(lastCommission);
            }
            if (role === "client") {
                const contact = await getContactClient();
                const resParrainge = await checkLastSubscribeForClient();
                setParrainage(resParrainge.isPartner);
                setContactClient(contact);
            }
            const statsSubscribes = await getStatsSubscribes();
            const lastArticles = await getLastArticles();
            const lastReporting = await getProductsByLastReportings();

            setNumSubscribes(statsSubscribes.numSubscribes);
            setTotalNetSubscription(statsSubscribes.totalNetSubscription);
            setArticles(lastArticles);
            setReporting(lastReporting);

            setIsLoading(false);
        }
        loadData();
    }, [role]);

    const handleDownloadCommission = () => {

        if (commission && commission.path) {
            const urlArray = commission.path.split("/");
            const numberOfSubfolders = urlArray.length - 2;
            const fileName = urlArray[urlArray.length - 1];
            const filePath = numberOfSubfolders === 1
                ? `${urlArray[1]}/${fileName}`
                : `${urlArray[1]}/${urlArray[2]}/${fileName}`;

            handleDownload(filePath, fileName);
        } else {
            throw new Error("Impossible de télécharger la commission");
        }
    };

    const handleDownloadReporting = () => {

        if (reporting && reporting.documentProducts[0].path) {
            const urlArray = reporting.documentProducts[0].path.split("/");
            const numberOfSubfolders = urlArray.length - 2;
            const fileName = urlArray[urlArray.length - 1];
            const filePath = numberOfSubfolders === 1
                ? `${urlArray[1]}/${fileName}`
                : `${urlArray[1]}/${urlArray[2]}/${fileName}`;

            handleDownload(filePath, fileName);
        } else {
            throw new Error("Impossible de télécharger la commission");
        }
    };

    const handleDownload = async (fileRelativePath, fileName) => {
        try {
            const response = await downloadFile(fileRelativePath);
            const file = new Blob([response.data], { type: "application/pdf" });
            saveAs(file, fileName);
        } catch (error) {
            throw error;
        }
    };


    const openArticle = (urlArticle) => {
        window.open(urlArticle, '_blank');
    }

    const openParrainage = () => {
        window.location.href = "/parrainage";
    }
    return (
        <PageContainer isLoading={isLoading}>
            <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/144566134.js"></script>
            {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status"></Spinner>
                </div>
            ) : (
                <>
                    <Container className="my-5">
                        <h1 className="text-center my-2 mb-5" style={{color: "#092A6C"}}>TABLEAU DE BORD</h1>
                        <Row className="mb-4 justify-content-center">
                            <Col md={6}>
                                <Card className="shadow h-100">
                                    <Card.Body className="text-center">
                                        <Card.Title style={{color: "#092A6C"}}>Nombre de Souscriptions</Card.Title>
                                        <Card.Text style={{color: "black"}}>{numSubscribes.toLocaleString('fr-FR')}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Card className="shadow h-100">
                                    <Card.Body  className="text-center">
                                        <Card.Title style={{color: "#092A6C"}} >Total des Souscriptions nettes</Card.Title>
                                        <Card.Text style={{color: "black"}}>{totalNetSubscription.toLocaleString('fr-FR')}€</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Card className="mb-4 shadow">
                                    <Card.Body>
                                        <Card.Title className="text-center" style={{color: "#092A6C"}}>Souscriptions par année</Card.Title>
                                        <Chart />
                                    </Card.Body>
                                </Card>
                                <Card className="mb-4 shadow">
                                    <Card.Body className="text-center">
                                        <Card.Title>L'univers d'investissement France Valley</Card.Title>
                                        <Card.Img src={process.env.PUBLIC_URL + `./carte.png`} alt="carte"  /* style={{ maxWidth: '400px', height: 'auto' }} */ />
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={6}>
                                    {role === "conseiller" ? (
                                        <>
                                             {commission ? (
                                                  <Card className="mb-4 shadow" style={{ height: '172px' }}>
                                                    <Card.Body className="text-center" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Card.Text style={{ color: '#092A6C' }}>Télécharger votre dernier relevé de commissions</Card.Text>
                                                        <FontAwesomeIcon icon={faFileArrowDown} style={{ width: '40px', height: '40px', color: '#092A6C', cursor: 'pointer' }} onClick={handleDownloadCommission} />
                                                        <Card.Text style={{ color: '#092A6C', cursor: 'pointer' }} onClick={handleDownloadCommission}>{formatDate(commission.date)}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                             ) : (
                                                <Card className="mb-4 shadow" style={{ height: '172px' }}>
                                                    <Card.Body className="text-center" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                        <FontAwesomeIcon icon={faFileArrowDown} style={{ width: '40px', height: '40px', color: '#092A6C' }}/>
                                                        <Card.Text style={{ color: '#092A6C' }}>Votre dernier relevé de commissions apparaîtra ici</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                             )}
    
                                             {reporting ? (
                                                  <Card className="mb-4 shadow" style={{ backgroundColor: 'white', color: "#ffffff", height: '172px' }}>
                                                    <Card.Body className="text-center" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Card.Text style={{ color: '#092A6C' }}>Télécharger le dernier reporting</Card.Text>
                                                        <FontAwesomeIcon icon={faFileArrowDown} style={{ width: '40px', height: '40px', color: '#092A6C', cursor: 'pointer' }} onClick={handleDownloadReporting} />
                                                        <Card.Text style={{ color: '#092A6C', cursor: 'pointer' }} onClick={handleDownloadReporting}>{reporting.productName}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                             ) : (
                                                <Card className="mb-4 shadow" style={{ backgroundColor: 'white', color: "#ffffff", height: '172px' }}>
                                                    <Card.Body className="text-center" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                        <FontAwesomeIcon icon={faFileArrowDown} style={{ width: '40px', height: '40px', color: '#092A6C' }}/>
                                                        <Card.Text style={{ color: '#092A6C' }}>Le dernier reporting apparaîtra ici</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                             )}
                                         </>
                                    ) : (
                                        <>
                                            {reporting ? (
                                                  <Card className="mb-4 shadow" style={{ backgroundColor: 'white', color: "#ffffff", height: '172px' }}>
                                                    <Card.Body className="text-center" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Card.Text style={{ color: '#092A6C' }}>Télécharger le dernier reporting</Card.Text>
                                                        <FontAwesomeIcon icon={faFileArrowDown} style={{ width: '40px', height: '40px', color: '#092A6C', cursor: 'pointer' }} onClick={handleDownloadReporting} />
                                                        <Card.Text style={{ color: '#092A6C', cursor: 'pointer' }} onClick={handleDownloadReporting}>{reporting.productName}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                                ) : (
                                                    <Card className="mb-4 shadow" style={{ backgroundColor: 'white', color: "#ffffff", height: '172px' }}>
                                                        <Card.Body className="text-center" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                        <FontAwesomeIcon icon={faFileArrowDown} style={{ width: '40px', height: '40px', color: '#092A6C' }}/>
                                                        <Card.Text style={{ color: '#092A6C' }}>Le dernier reporting apparaîtra ici</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            )}

                                            {parrainage ? (
                                                <Card className="mb-4 shadow" style={{height: '172px', backgroundColor: '#092A6C'}} onClick={() => openParrainage()}>
                                                    <Card.Body style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                                    <Row style={{ width: '100%' }}>
                                                        <Col xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <FaGlassCheers style={{ width: '80px', height: '80px', color: '#86D3F4' }}/>
                                                        </Col>
                                                        <Col xs={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                            <p style={{ color: '#86D3F4', marginBottom: '0', fontSize: '20px' }}>
                                                            <strong>
                                                                OFFRE DE PARRAINAGE
                                                            </strong>
                                                            </p>

                                                            <p style={{ color: 'white', fontSize: '20px' }}>
                                                            Une caisse de Champagne d'un vigneron de France Valley pour vous et votre filleul
                                                            </p>
                                                        </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            ) : (
                                                <>
                                                {contactClient && (
                                                    <Card className="mb-4 shadow" style={{height: '172px'}}>
                                                    <Card.Body className="text-center" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Row>
                                                            <Col xs={4}>
                                                                <Image src={contactClient.pathImage} roundedCircle className="mx-auto d-block" style={{ height: "130px"}}/>
                                                            </Col>
                                                            <Col xs={8}>
                                                                <p style={{color: '#092A6C'} }>
                                                                    <strong>
                                                                        {contactClient.firstName} {contactClient.lastName}
                                                                    </strong>
                                                                </p>
                                                                {contactClient.email && (
                                                                    <p>
                                                                        <FaEnvelope className="mr-2"/>
                                                                        <span href="`mailto:$"> {contactClient.email}</span>
                                                                    </p>
                                                                )}
                                                                {contactClient.landline && (
                                                                    <p>
                                                                        <FaPhone className="mr-2"/>
                                                                        <span> {contactClient.landline}</span>
                                                                    </p>
                                                                )}
                                                                {contactClient.phoneNumber && (
                                                                    <p>
                                                                        <FaMobileAlt className="mr-2" />
                                                                        <span> {contactClient.phoneNumber}</span>
                                                                    </p>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                                )}
                                                </>
                                                )}

                                            
                                        </>
                                    )}
                                   
                                 <Card className="mb-4 shadow" style={{ backgroundColor: 'white', color: "#ffffff" }}>
                                    <Card.Body>
                                        <Card.Title className="text-center"  style={{ color: '#092A6C'}} >Dernière Actualité</Card.Title>
                                        {articles.map((article, index) => (
                                            <div onClick={() => openArticle(article.urlArticle)} style={{ cursor: 'pointer', textDecoration: 'none' }} className="article-item">
                                                <div key={index}  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                                    <Card.Img variant="top" src={process.env.PUBLIC_URL + `${article.urlImage}`} alt={article.title} style={{  width: '100%', height: '200px', objectFit: 'cover' }} />
                                                </div>
                                                <strong >{article.title}</strong>
                                                <Card.Subtitle className="mb-2 text-muted">{formatDate(article.date)}</Card.Subtitle>
                                                <p style={{ color: 'black' }}>{article.message}</p>
                                            </div>
                                        ))}
                                    </Card.Body>
                                </Card>
                               
                                <Card className="mb-4 shadow" style={{ backgroundColor: '#092A6C' }}>
                                    <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Card.Title className="text-center" style={{ color: '#092A6C', fontSize: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '1' }}>
                                            <span style={{ fontSize: '32px', color: "#86D3F4" }}>4,4</span>
                                        </Card.Title>
                                        <Card.Text className="text-center" style={{ color: '#86D3F4', fontSize: '25px', display: 'flex', justifyContent: 'center', }}>Milliards d'euros d'actifs gérés*</Card.Text>
                                        <Card.Text style={{ color: '#86D3F4', fontSize: '14px', textAlign: 'center' }}>
                                            *Pour l'activité immobilière, les actifs SCPI sont comptabilisés en valeur de pleine propriété
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <div className="row">
                                    <div className="col-md-4">
                                        <Card className="mb-4 shadow" style={{ backgroundColor: '#092A6C', height: '80px'  }}>
                                        <Card.Body  className="text-center" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <Card.Title style={{color: "#86D3F4"}} >46 000 hectares de forêts</Card.Title>
                                        </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-md-4">
                                        <Card className="mb-4 shadow" style={{ backgroundColor: '#092A6C', height: '80px' }}>
                                        <Card.Body  className="text-center" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <Card.Title style={{color: "#86D3F4"}} >Plus de 400 forêts</Card.Title>
                                        </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-md-4">
                                        <Card className="mb-4 shadow" style={{ backgroundColor: '#092A6C' , height: '80px' }}>
                                        <Card.Body  className="text-center" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <Card.Title style={{color: "#86D3F4"}} >20 000 investisseurs</Card.Title>
                                        </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </>
            )}
        </PageContainer>

    );

}

export default Dashboard;
