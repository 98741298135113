import React from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

function Page404() {
    return (
        <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
        }}
    >
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "40px 50px",
                border: "1px solid #ccc",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
                maxWidth: "500px",
                width: "100%",
                boxSizing: "border-box",
            }}
        >
            <img
                src={process.env.PUBLIC_URL + "/LOGO-FV.png"}
                alt="Logo"
                style={{ maxWidth: "100%", marginBottom: "30px" }}
            />
                <h1>404</h1>
                <h2>Page non trouvée</h2>
                <p>La page que vous recherchez semble introuvable.</p>
        </div>
    </div>
    );
}

export default Page404;
