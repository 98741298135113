import axios from "axios";

export const getFondsByRole = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/fonds/role" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getFondsByRoleWithEntranceFee = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/fonds/role/linkEntranceFee" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getFondsREsubscribeByRoleWithEntranceFee = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/fonds/resubscribe/role/linkEntranceFee" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

/*export const getFondById = async (fondId) => {
    try {
        const response = await axios.get(`http://127.0.0.1:3001/api/subscribes/fonds/${fondId}` , { withCredentials: true });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}*/


export const getSyntheseFonds = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/synthesefonds/" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}
