import React from "react";
import ForgotPassword from "../components/ForgotPassword";
function ForgotPasswordConseiller() {

    return (
        <>
            <ForgotPassword type="conseiller" />
        </>
    );
}

export default ForgotPasswordConseiller;
