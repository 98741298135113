import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Card,
    Container,
    Form,
    ListGroup,
    Row,
    Col,
} from "react-bootstrap";
import { BsDownload } from "react-icons/bs";
import {downloadFile} from "../services/Product";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import '../css/Fonds.css';
import Spinner from "react-bootstrap/Spinner";
import {FiEye, FiEyeOff} from "react-icons/fi";
import { checkLogin, downloadFileDoccenter, getDocEngagementForestiers, getFondDocumentsDoccenterByFondId, getFondsDoccenter, getSimulations, getSyntheseFondsDoccenter, loginDoccenter } from "../services/Doccenter";

function DocCenter() {
    const [selectedFond, setSelectedFond] = useState("");
    const [fonds, setFonds] = useState([]);
    const [fondDocuments, setFondDocuments] = useState([]);
    const [fondId, setFondId] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const documentsRef = useRef(null);
    const [syntheseFonds, setSyntheseFonds] = useState({});
    const [selectedFondsSubscribe, setSelectedFondsSubscribe] = useState(null);
    const [showEntranceFees, setShowEntranceFees] = useState(false);
    const [entranceFees, setEntranceFees] = useState([]);
    const [password, setPassword] = useState('');
    const [authenticated, setAuthenticated] = useState(false);
    const [isLoadingLogin, setIsLoadingLogin] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [simulations, setSimulations] = useState([]);
    const [selectedSimulation, setSelectedSimulation] = useState("");
    const [selectedDocEngagementForestiers, setSelectedDocEngagementForestiers] = useState("");
    const [docEngagementForestiers, setDocEngagementForestiers] = useState([]);
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoadingLogin(true);
        try {
            const response = await loginDoccenter({
                password,
            });
            if (response.status === 200) {
                setAuthenticated(true);
            } 
        } catch (error) {
            setErrorMessage(error.response.data.error || "Une erreur s'est produite. Veuillez réessayer.");
        } finally {
            setIsLoadingLogin(false); // Fin du chargement (succès ou échec)
        }
      };

      const handleInputChange = () => {
        setErrorMessage("");
    };
    


    useEffect(() => {
        async function getFondsLoad() {
            const checkLog = await checkLogin();
            if (checkLog.checkLogin === true) {
                setAuthenticated(true);
            }

            if(authenticated === true) {
                const res = await getFondsDoccenter();
                setFonds(res);
                const resSynthese = await getSyntheseFondsDoccenter();
                setSyntheseFonds(resSynthese);
                const resSimulations = await getSimulations();
                setSimulations(resSimulations);
                const resDocEngagementForestiers = await getDocEngagementForestiers();
                setDocEngagementForestiers(resDocEngagementForestiers);
            }
            setIsLoading(false)
        }
        getFondsLoad();
    }, [authenticated]);

    const handleFondChange = async (event) => {
        setSelectedFond(event.target.value);
        const currentFond = fonds.find((fond) => fond.nameFond === event.target.value);
        if (currentFond) {
            setFondId(currentFond.id);
            const fondDocs = await getFondDocumentsDoccenterByFondId(currentFond.id);
            setFondDocuments(fondDocs);

           // faire un scroll vers les documents
            setTimeout(() => {
                if (documentsRef.current) {
                    documentsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 100);
        } else {
            setFondId(null);
            setFondDocuments([]);
        }
    };


    const downloadSingleFile = async (fileRelativePath, fileName) => {
        try {
            const urlArray = fileRelativePath.split('/');
            const numberOfSubfolders = urlArray.length - 2;
            const fileName = urlArray[urlArray.length - 1];
            const filePath = numberOfSubfolders === 1
                ? `${urlArray[1]}/${fileName}`
                : `${urlArray[1]}/${urlArray[2]}/${fileName}`;

            const response = await downloadFileDoccenter(filePath);
            const blob = new Blob([response.data], { type: response.headers["content-type"] });
            saveAs(blob, fileName);
        } catch (error) {
            throw error;
        }
    };

    const handleDownloadSimulation = () => {
        const selectedSimulationObj = simulations.find(
            (simulation) => simulation.nameDoc === selectedSimulation
        );

        if (selectedSimulationObj && selectedSimulationObj.path) {
            const urlArray = selectedSimulationObj.path.split("/");
            const numberOfSubfolders = urlArray.length - 2;
            const fileName = urlArray[urlArray.length - 1];
            const filePath = numberOfSubfolders === 1
                ? `${urlArray[1]}/${fileName}`
                : `${urlArray[1]}/${urlArray[2]}/${fileName}`;

            handleDownload(filePath, fileName)
        } else {
            throw new Error("Impossible de télécharger le fichier");
        }
    };

    const handleDownloadDocEngagementForestiers = () => {
        const selectedDocEngagementForestiersObj = docEngagementForestiers.find(
            (docEngagementForestiers) => docEngagementForestiers.nameDoc === selectedDocEngagementForestiers
        );

        if (selectedDocEngagementForestiersObj && selectedDocEngagementForestiersObj.path) {
            const urlArray = selectedDocEngagementForestiersObj.path.split("/");
            const numberOfSubfolders = urlArray.length - 2;
            const fileName = urlArray[urlArray.length - 1];
            const filePath = numberOfSubfolders === 1
                ? `${urlArray[1]}/${fileName}`
                : `${urlArray[1]}/${urlArray[2]}/${fileName}`;

            handleDownload(filePath, fileName)
        } else {
            throw new Error("Impossible de télécharger le fichier");
        }
    };

    const handleDownload = async (fileRelativePath, fileName) => {
        try {
            const response = await downloadFileDoccenter(fileRelativePath);
            const file = new Blob([response.data], { type: "application/pdf" });
            saveAs(file, fileName);
        } catch (error) {
           throw error;
        }
    };


    const downloadAllFiles = async (fondId) => {
        try {
            const fond = fonds.find((fond) => fond.id === fondId);
            if (!fond) {
                console.error("Fond introuvable");
                return;
            }

            const zip = new JSZip();

            const files = await Promise.all(
                fondDocuments.map(async (document) => {
                    const urlArray = document.path.split("/");
                    const numberOfSubfolders = urlArray.length - 2;
                    const fileName = urlArray[urlArray.length - 1];
                    const filePath = numberOfSubfolders === 1
                        ? `${urlArray[1]}/${fileName}`
                        : `${urlArray[1]}/${urlArray[2]}/${fileName}`;

                    const response = await downloadFileDoccenter(filePath);
                    const file = new Blob([response.data], { type: "application/pdf" });
                    return { name: fileName, file: file };
                })
            );

            files.forEach(({ name, file }) => {
                zip.file(name, file);
            });

            const content = await zip.generateAsync({ type: "blob" });
            saveAs(content, `${fond.nameFond}-documents.zip`);
        } catch (error) {
            throw error;
        }
    };

    const downlaodSyntheseFonds = async () => {
        try {
            const urlArray = syntheseFonds[0].path.split('/');
            const numberOfSubfolders = urlArray.length - 2;
            const fileName = urlArray[urlArray.length - 1];
            const filePath = numberOfSubfolders === 1
                ? `${urlArray[1]}/${fileName}`
                : `${urlArray[1]}/${urlArray[2]}/${fileName}`;

            const response = await downloadFileDoccenter(filePath);
            const blob = new Blob([response.data], { type: response.headers["content-type"] });
            saveAs(blob, 'Synthèse.pdf');
        } catch (error) {
            throw error;
        }
    };

    const fondSelected = fonds.find((fond) => fond.nameFond === selectedFond);

    const handleFondsSubscribeChange = (e) => {
        const selectedFondsSubscribe = fonds.find(fond => fond.id === parseInt(e.target.value, 10));
        setSelectedFondsSubscribe(selectedFondsSubscribe);
    
        const shouldRedirect = (selectedFondsSubscribe && selectedFondsSubscribe.linkEntranceFees.length <= 1);
    
        if (selectedFondsSubscribe && shouldRedirect) {
            window.open(selectedFondsSubscribe.linkEntranceFees[0].url, "_blank");
            setShowEntranceFees(false);
        } else if (selectedFondsSubscribe) {
            setEntranceFees(selectedFondsSubscribe.linkEntranceFees || []);
            setShowEntranceFees(true);
        }
    };

    const handleEntranceFeeClick = (url) => {
        window.open(url, "_blank");
    };

    const handleSimulationChange = (event) => {
        setSelectedSimulation(event.target.value);
    };

    const handleDocEngagementForestiersChange = (event) => {
        setSelectedDocEngagementForestiers(event.target.value);
    };


    if (!authenticated) {
        return (
            <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <div
                style={{
                    padding: "40px 50px",
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fff",
                    maxWidth: "500px",
                    width: "100%",
                    boxSizing: "border-box",
                }}
            >
                <form
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    onSubmit={handleSubmit}
                >
                    <h2 style={{ marginBottom: "30px" }}>
                        Centre Documentaire
                    </h2>
                    <img
                        src={process.env.PUBLIC_URL + "/LOGO-FV.png"}
                        alt="Logo"
                        style={{ maxWidth: "100%", marginBottom: "30px" }}
                    />
                    <>
                        <div
                            style={{
                                position: 'relative',
                                marginBottom: '20px',
                                display: 'inline-block',
                                width: '100%'
                            }}
                        >
                            <input
                                style={{
                                    padding: "15px",
                                    width: "100%",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                    paddingRight: '40px',
                                }}
                                type={showPassword ? "text" : "password"}
                                placeholder="Mot de passe"
                                value={password}
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                    handleInputChange();
                                }}
                                required
                            />
                            <button
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    cursor: 'pointer',
                                    fontSize: '20px',
                                    color: "#aaa"
                                }}
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowPassword(!showPassword);
                                }}
                            >
                                {showPassword ? <FiEye /> : <FiEyeOff /> }
                            </button>
                        </div>
                    </>
                    {errorMessage && (
                        <p style={{ color: "red" }}>{errorMessage}</p>
                    )}
                    <button
                        style={{
                            backgroundColor: "#0066CC",
                            color: "#fff",
                            padding: "15px",
                            borderRadius: "5px",
                            border: "none",
                            cursor: "pointer",
                            width: "100%",
                        }}
                        
                        type="submit"
                        disabled={isLoadingLogin} // Désactiver le bouton pendant le chargement
                    >
                        {isLoadingLogin ? (
                            <span className="spinner-border spinner-border-sm me-2" type="submit" role="status" aria-hidden="true"></span>
                        ) : (
                            "Se connecter"
                        )}
                    </button>
                </form>
            </div>
        </div>
        );
      }

    return (
        <Container>
            {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <>
                    <Container className="my-5">
                        <h1 className="my-2 text-center" style={{ color: "#092A6C" }}>
                            Centre Documentaire
                        </h1>
                        
                        <Card className="shadow-lg my-3 p-4 rounded">
                                <>
                                    <div className=" mt-2 p-4 rounded border border-primary bg-light text-center">
                                    <img
                                        src={process.env.PUBLIC_URL + "/fonds.jpg"}
                                        alt="fonds"
                                        className="img-fluid"
                                    />
                                         <Button
                                         onClick={() => downlaodSyntheseFonds()}
                                         className="btn btn-lg active mt-3 btn-custom"
                                     >
                                         Cliquer pour obtenir la synthèse de l'offre
                                     </Button>
                                </div>
                                
                            
                                <p className="mt-3">
                                Vous pouvez télécharger ci-dessous la documentation nécessaire à la commercialisation des Groupements Forestiers et Viticoles de France Valley. 
                                </p>
                                <p className="mb-3">
                                Pour rappel, les GFV et certains Fonds Forestiers sont des produits qui ne peuvent faire l’objet de publicité et de démarchage bancaire et financier. Ces solutions d’investissement ne doivent être proposées que sur demande de vos clients, dans la limite de 150 sollicitations par produit. Nous vous demandons donc de faire signer, préalablement à la présentation des produits, un mandat de recherche (dont vous trouverez un modèle ci-dessous), et de nous signaler cette sollicitation. Prenez donc le soin de ne présenter ces éléments qu’aux personnes dont vous pensez qu’elles ont une probabilité forte de souscrire.
                                </p>
                            </>
                            <Row>
                                <Col>
                                    <Form.Group className="my-3">
                                            <Form.Label>
                                                <strong>Nos solutions d'investissement :</strong>
                                            </Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={selectedFond}
                                            onChange={handleFondChange}
                                            className="form-select shadow-sm"
                                        >
                                            <option value="">Sélectionnez une solution</option>
                                            {fonds.map((fond) => (
                                                <option key={fond.id} value={fond.nameFond}>
                                                    {fond.nameFond}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    {fondSelected && (
                                        <Card className="my-3" ref={documentsRef}>
                                            <Card.Header>{fondSelected.nameFond}</Card.Header>
                                            <ListGroup variant="flush">
                                                {fondDocuments.map((doc, index) => (
                                                    <ListGroup.Item key={index}>
                                                        <Row>
                                                            <Col className="doc-name">
                                                                <span>{doc.nameDoc}</span>
                                                            </Col>
                                                            <Col className="text-end">
                                                                <Button
                                                                    className="btn-custom" 
                                                                    size="sm"
                                                                    onClick={() =>
                                                                        downloadSingleFile(doc.path, doc.nameDoc)
                                                                    }
                                                                >
                                                                    <BsDownload />
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                            <Card.Footer>
                                                {fondDocuments.length > 1 ? (
                                                    <Button className="btn-custom" onClick={() => downloadAllFiles(fondId)}>
                                                        <BsDownload /> Télécharger tous les documents
                                                    </Button>
                                                ) : null
                                                }
                                            </Card.Footer>
                                        </Card>
                                    )}
                                </Col>
                                <Col>
                                   <div className="my-3">
                                        <a><strong>Convention de Distribution :</strong></a> <br/>
                                        <a href="https://france-valley.com/convention" target="_blank">Signature en ligne de la Convention de Distribution</a>
                                   </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                 <div className="mt-3">
                                        <Form>
                                            <Form.Group controlId="fondsSelect">
                                           <Form.Label>
                                               <strong>Souscrire en ligne :</strong>
                                            </Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    className="form-select shadow-sm"
                                                    onChange={handleFondsSubscribeChange}
                                                >
                                                    <option value="">Sélectionnez un fonds</option>
                                                    {fonds.map((fond) => (
                                                        <option key={fond.id} value={fond.id}>
                                                            {fond.nameFond}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Form>
                                        </div>
                                        {selectedFondsSubscribe && showEntranceFees && (
                                            <div className="mt-3">
                                                <h5>Les droits d'entrée :</h5>
                                                <ListGroup className="entrance-fees-list">
                                                    {entranceFees.map((entranceFee) => (
                                                        <ListGroup.Item
                                                            key={entranceFee.id}
                                                            action
                                                            onClick={() => handleEntranceFeeClick(entranceFee.url)}
                                                            className="entrance-fee-item"
                                                        >
                                                            {entranceFee.entranceFee}
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>
                                            </div>
                                        )}
                                </Col>
                                <Col>
                                    <div className="my-3">
                                        <a><strong>Déclaration d'Origine des Fonds :</strong></a> <br/>
                                        <p>Pour toute souscription d'un client supérieure à 90.000 euros sur 12 mois glissants, un Justificatif d'Origine des Fonds est demandé et peut être complété directement en ligne <a href="https://france-valley.com/JOF" target="_blank">Déclaration d'Origine des Fonds</a></p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="my-3">
                                        <Form.Label>
                                        <strong>Nos documents ESG :</strong>
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            className={"shadow-sm form-select"}
                                            value={selectedDocEngagementForestiers}
                                            onChange={handleDocEngagementForestiersChange}
                                        >
                                            <option value="">Sélectionnez un document</option>
                                            {docEngagementForestiers.map((doc) => (
                                                <option key={doc.id} value={doc.fileName}>
                                                    {doc.nameDoc}
                                                </option>
                                            ))}
                                        </Form.Control>

                                    </Form.Group>
                                    <div className="my-3">
                                        <Button
                                            className="btn-custom"
                                            onClick={handleDownloadDocEngagementForestiers}
                                            disabled={!selectedDocEngagementForestiers}
                                        >
                                            <BsDownload className="me-2" />
                                            Télécharger le document
                                        </Button>
                                    </div>
                                </Col>
                                <Col>
                                <Form.Group className="my-3">
                                        <Form.Label>
                                        <strong>Choisir une Simulation :</strong>
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            className={"shadow-sm form-select"}
                                            value={selectedSimulation}
                                            onChange={handleSimulationChange}
                                        >
                                            <option value="">Sélectionnez une simulation</option>
                                            {simulations.map((simulation) => (
                                                <option key={simulation.id} value={simulation.fileName}>
                                                    {simulation.nameDoc}
                                                </option>
                                            ))}
                                        </Form.Control>

                                    </Form.Group>
                                    <div className="my-3">
                                        <Button
                                            className="btn-custom"
                                            onClick={handleDownloadSimulation}
                                            disabled={!selectedSimulation}
                                        >
                                            <BsDownload className="me-2" />
                                            Télécharger la simulation
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </>
                    )}
                </Container>
                

    );
}

export default DocCenter;
