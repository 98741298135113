import React from "react";
import {useNavigate} from "react-router-dom";


// Modal.setAppElement("#root");

function Login() {

    const navigate = useNavigate();

    const redirectToLoginClient = () => {
        navigate('/loginClient');
    };
    const redirectToLoginPartner = () => {
        navigate('/loginPartner');
    };

    const buttonStyles = {
        backgroundColor: "#0066CC",
        color: "#fff",
        padding: "15px",
        borderRadius: "5px",
        border: "none",
        cursor: "pointer",
        width: "100%",
        marginBottom: "20px",
        transition: "0.3s",
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "40px 50px",
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fff",
                    maxWidth: "500px",
                    width: "100%",
                    boxSizing: "border-box",
                }}
            >
                <h2 style={{ marginBottom: "30px" }}>Extranet</h2>
                <img
                    src={process.env.PUBLIC_URL + "/LOGO-FV.png"}
                    alt="Logo"
                    style={{ maxWidth: "100%", marginBottom: "30px" }}
                />
                <button
                    style={buttonStyles}
                    onMouseEnter={(e) => (e.target.style.backgroundColor = "#0047b3")}
                    onMouseLeave={(e) => (e.target.style.backgroundColor = "#0066CC")}
                    onClick={redirectToLoginClient}
                >
                    Client
                </button>
                <button
                    style={buttonStyles}
                    onMouseEnter={(e) => (e.target.style.backgroundColor = "#0047b3")}
                    onMouseLeave={(e) => (e.target.style.backgroundColor = "#0066CC")}
                    onClick={redirectToLoginPartner}
                >
                    Partenaire
                </button>
            </div>

            {/*<Modal
                isOpen={isIdCardExpiredModalOpen}
                onRequestClose={() => setIsIdCardExpiredModalOpen(false)}
                style={customStyles}
            >
                <h2>Attention</h2>
                <p>Script au moment de la connexion pop up qui vérifie la date si elle pas expiré si elle l’est mettre ce message Madame, Monsieur, la pièce d'identité en notre possession est arrivée à expiration le XX/XX/XXXX, et nous vous invitons à déposer la copie de votre Carte Nationale d'Identité ou de votre passeport en cours de validité, directement sur la page accessible à l'aide du lien suivant : redirigé vers la page formulaire de contact.
                </p>
                <button onClick={() => setIsIdCardExpiredModalOpen(false)}>OK</button>
            </Modal>*/}
        </div>
    );
}

export default Login;
