import axios from 'axios';
export const getUserById = async () => {
    try {
        const response = await axios.get('https://dev.france-valley.paris/api/users/id', { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const checkSubOnlineUser = async () => {
    try {
        const response = await axios.get('https://dev.france-valley.paris/api/users/checkSubOnline', { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}
