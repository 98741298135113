
import axios from "axios";

/*export const getPartnerById = async (partnerId) => {
    try {
        const response = await axios.get(`http://127.0.0.1:3001/api/partners/${partnerId}` , { withCredentials: true });
        return response.data;
    } catch (error) {
        console.error(error);
    }
}*/

export const getPartnerByUserId = async () => {
    try {
        const response = await axios.get('https://dev.france-valley.paris/api/Partners/user/userId' , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const checkLastSubOnlineForPartner = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/partners/clients/subscribeOnline" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}
