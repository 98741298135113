import {useEffect, useRef, useState} from 'react';
import { Chart, LinearScale, CategoryScale, Title, BarController, BarElement} from 'chart.js';
import 'chartjs-adapter-date-fns';
import {getStatsSubscribes} from "../services/Subscribe";

Chart.register(BarElement,LinearScale, CategoryScale, Title, BarController);

const MyChart = () => {
    const chartRef = useRef(null);
    const [yearSubscribes, setYearSubscribes] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const statsSubscribes = await getStatsSubscribes();
            setYearSubscribes(statsSubscribes.yearSubscribes);
        };
        fetchData();
    }, [])

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.destroy();
        }

        const ctx = document.getElementById('myChart').getContext('2d');
        chartRef.current = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: yearSubscribes.map(item => item.year),
                datasets: [
                    {
                        label: 'Total par année',
                        data: yearSubscribes.map(item => item.total),
                        backgroundColor: '#092A6C',
                        borderColor: '#092A6C',
                        borderWidth: 1,
                    }
                ],
            },
            options: {
                scales: {
                    x: {
                        type: 'category',
                        title: {
                            display: true,
                            text: 'Année'
                        }
                    },
                    y: {
                        type: 'linear',
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Total des souscriptions'
                        },
                        ticks: {
                            callback: function(value, index, values) {
                                return value.toLocaleString('fr-FR') + '€';   // Ajoute le suffixe € à chaque valeur d'échelle
                            }
                        }
                    }
                },
            },
        });
    }, [yearSubscribes]);

    return <canvas className='chart' id="myChart" />;
};

export default MyChart;
