import axios from 'axios';

export const getConseillersByPartner = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/conseillers/partner" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}
