import React, {useEffect, useState} from "react";
import { Card, Container, Form, Button } from "react-bootstrap";
import { BsDownload } from "react-icons/bs";
import {getSimulations} from "../services/Simulation";
import {downloadFile} from "../services/Product";
import { saveAs } from "file-saver";
import Spinner from "react-bootstrap/Spinner";
import PageContainer from "../components/PageContainer";
import '../css/Simulations.css';

function Simulations() {
    const [selectedSimulation, setSelectedSimulation] = useState("");
    const [simulations, setSimulations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function getSimulationsLoad() {
            const res = await getSimulations();
            setSimulations(res);
            setIsLoading(false);
        }
        getSimulationsLoad();
    }, []);

    const handleSimulationChange = (event) => {
        setSelectedSimulation(event.target.value);
    };

    const handleDownloadSimulation = () => {
        const selectedSimulationObj = simulations.find(
            (simulation) => simulation.nameDoc === selectedSimulation
        );

        if (selectedSimulationObj && selectedSimulationObj.path) {
            const urlArray = selectedSimulationObj.path.split("/");
            const numberOfSubfolders = urlArray.length - 2;
            const fileName = urlArray[urlArray.length - 1];
            const filePath = numberOfSubfolders === 1
                ? `${urlArray[1]}/${fileName}`
                : `${urlArray[1]}/${urlArray[2]}/${fileName}`;

            handleDownload(filePath, fileName)
        } else {
            throw new Error("Impossible de télécharger le fichier");
        }
    };

    const handleDownload = async (fileRelativePath, fileName) => {
        try {
            const response = await downloadFile(fileRelativePath);
            const file = new Blob([response.data], { type: "application/pdf" });
            saveAs(file, fileName);
        } catch (error) {
           throw error;
        }
    };




    return (
        <PageContainer isLoading={isLoading}>
            {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <>
                    <Container className="my-5">
                    <h1 className="text-center my-2" style={{ color: "#092A6C" }}>
                        Les Simulations
                    </h1>
                    <Card className="shadow-lg my-3">
                        <Card.Body>
                            <div className=" mt-2 p-4 rounded border border-primary bg-light ">
                                <h4 className="mb-3 " style={{color: '#092A6C'}}>Avertissements importants :</h4>
                                <p>
                                    France Valley décline toute responsabilité quant à l'utilisation de ces simulations, qui restent de la seule responsabilité du Partenaire.
                                    Les éléments donnés dans les simulations ne sont pas contractuels et sont donnés à titre purement illustratif, ils ne constituent en aucun cas une recommandation fiscale.
                                    Par ailleurs, chaque situation fiscale doit être étudiée individuellement.
                                    Les avantages fiscaux dont peuvent bénéficier les Fonds de France Valley ne sont pas tous cumulables et dépendent de la situation de chacun. Ces dispositifs sont susceptibles d'évoluer. La fiscalité ne doit pas être le principal objectif pour investir. Capital non garanti, rendements non garantis, liquidité non garantie.
                                </p>
                                <p>Vous trouverez ci-dessous un ensemble de simulations. </p>
                            </div>
                            <Form.Group className="my-3">
                                <Form.Label>Choisir une simulation :</Form.Label>
                                <Form.Control
                                    as="select"
                                    className={"shadow-sm form-select"}
                                    value={selectedSimulation}
                                    onChange={handleSimulationChange}
                                >
                                    <option value="">Sélectionnez une simulation</option>
                                    {simulations.map((simulation) => (
                                        <option key={simulation.id} value={simulation.fileName}>
                                            {simulation.nameDoc}
                                        </option>
                                    ))}
                                </Form.Control>

                            </Form.Group>
                            <div className="my-3">
                                <Button
                                    className="btn-custom"
                                    onClick={handleDownloadSimulation}
                                    disabled={!selectedSimulation}
                                >
                                    <BsDownload className="me-2" />
                                    Télécharger la simulation
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Container>
                </>
            )}
        </PageContainer>
    );
}

export default Simulations;
