import axios from "axios";

export const getFondDocumentsByFondId = async (fondId) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/fondDocuments/fond/${fondId}` , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

