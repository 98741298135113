import React, { useEffect, useState } from "react";
import { Card, Container, Form, Button } from "react-bootstrap";
import { downloadFile } from "../services/Product";
import { saveAs } from "file-saver";
import { BsDownload } from "react-icons/bs";
import Spinner from "react-bootstrap/Spinner";
import PageContainer from "../components/PageContainer";
import { getFraisExPostByPartner } from "../services/FraisExPost";
import '../css/FraisExPost.css';



function FraisExPost() {
    const [fraisExPost, setFraisExPost] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedFraisExPost, setSelectedFraisExPost] = useState("");

    useEffect(() => {
        async function getFraisExPostLoad() {
           const res = await getFraisExPostByPartner();
            setFraisExPost(res);
            setIsLoading(false);
        }
        getFraisExPostLoad();
    }, []);


    const handleFraisExPostChange = (event) => {
        setSelectedFraisExPost(event.target.value);
    };

    const handleDownloadFraisExPost = () => {
        const selectedFraisExPostObj = fraisExPost.find(
            (item) => item.path === selectedFraisExPost
        );

        if (selectedFraisExPostObj && selectedFraisExPostObj.path) {
            const urlArray = selectedFraisExPostObj.path.split("/");
            const numberOfSubfolders = urlArray.length - 2;
            const fileName = urlArray[urlArray.length - 1];
            const filePath = numberOfSubfolders === 1
                ? `${urlArray[1]}/${fileName}`
                : `${urlArray[1]}/${urlArray[2]}/${fileName}`;

            handleDownload(filePath, fileName);
        } else {
            throw new Error("Impossible de télécharger la commission");
        }
    };

    const handleDownload = async (fileRelativePath, fileName) => {
        try {
            const response = await downloadFile(fileRelativePath);
            const file = new Blob([response.data], { type: "application/pdf" });
            saveAs(file, fileName);
        } catch (error) {
            throw error;
        }
    };

    return (
        <PageContainer isLoading={isLoading}>
            {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <>
                    <Container className="my-5">
                        <h1 className="text-center my-2" style={{ color: "#092A6C" }}>Liste de mes Frais ex post</h1>
                        <Card className="shadow-lg my-3">
                            <Card.Body>
                                    <div className="mt-2 p-4 rounded border border-primary bg-light  ">
                                        <h4 className="mb-3" style={{ color: "#092A6C" }}>Les fichiers des frais ex-post mis à disposition par FRANCE VALLEY comprennent deux onglets :</h4>
                                        <p>
                                            Onglet « Mode d’emploi » : il détaille les différentes rubriques du second onglet,
                                        </p>
                                        <p> 
                                            Onglet « Data » : vous y retrouverez le détail des frais ex-post pour chacun de vos clients, et ce pour chaque produit souscrit.
                                        </p>
                                    </div>
                                <Form.Group className="my-3">
                                    <Form.Label>Choisir un Frais ex post :</Form.Label>
                                    <Form.Control
                                        as="select"
                                        className="form-select shadow-sm"
                                        value={selectedFraisExPost}
                                        onChange={handleFraisExPostChange}
                                    >
                                        <option value="">Sélectionnez un Frais ex post</option>
                                        {fraisExPost.map((item, index) => (
                                            <option key={index} value={item.path}>
                                                {item.annee}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <div className="my-3">
                                    <Button
                                        className="btn-custom"
                                        onClick={handleDownloadFraisExPost}
                                        disabled={!selectedFraisExPost}
                                    >
                                        <BsDownload className="me-2" />
                                        Télécharger le Frais ex post
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Container>
                </>
            )}
        </PageContainer>
    );
}

export default FraisExPost;

