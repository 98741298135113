import axios from "axios";

export async function exportSubscribesClient(format) {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/subscribes/exportClient/${format}`, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
            responseType: "blob",
        });

        const blob = new Blob([response.data], { type: response.headers["content-type"] });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Souscriptions.${format}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (error) {
        throw error;
    }
}

// export des souscriptions du partenaire avec ou sans recherche
export async function exportSubscribesByPartner(format, searchTerm = "") {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/subscribes/exportPartnerAll/${format}/${searchTerm}`, {
            withCredentials: true,
            responseType: "blob",
        });

        const blob = new Blob([response.data], { type: response.headers["content-type"] });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Souscriptions.${format}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

    } catch (error) {
        throw error;
    }
}



