import axios from "axios";

export const getTaxes2019 = async (limit = 10, offset = 0) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/taxes2019/partners?limit=${limit}&offset=${offset}` , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getTaxes2020 = async (limit = 10, offset = 0) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/taxes2020/partners?limit=${limit}&offset=${offset}` , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getTaxes2021 = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/taxes2021/partners" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getTaxes2022 = async () => {
    try {
        const response = await axios.get('https://dev.france-valley.paris/api/taxes2022/partners' , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getTaxes2023 = async (limit = 10, offset = 10) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/taxes2023/partners?limit=${limit}&offset=${offset}` , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const getTaxes2024 = async (limit = 10, offset = 10) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/taxes2024/partners?limit=${limit}&offset=${offset}` , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const getTaxes2019ByClientId = async (clientId) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/taxes2019/client/${clientId}` , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getTaxes2020ByClientId = async (clientId) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/taxes2020/client/${clientId}` , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}
export const getTaxes2021ByClientId = async (clientId) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/taxes2021/client/${clientId}` , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}
export const getTaxes2022ByClientId = async (clientId) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/taxes2022/client/${clientId}` , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}
export const getTaxes2023ByClientId = async (clientId) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/taxes2023/client/${clientId}` , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getTaxes2024ByClientId = async (clientId) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/taxes2024/client/${clientId}` , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getSearchClient = async (searchTerm) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/taxes2019/partners/clients/search?searchTerm=${searchTerm}`, {
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getSearchClient2020 = async (searchTerm) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/taxes2020/partners/clients/search?searchTerm=${searchTerm}`, {
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getSearchClient2021 = async (searchTerm) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/taxes2021/partners/clients/search?searchTerm=${searchTerm}`, {
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getSearchClient2022 = async (searchTerm) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/taxes2022/partners/clients/search?searchTerm=${searchTerm}`, {
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getSearchClient2023 = async (searchTerm) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/taxes2023/partners/clients/search?searchTerm=${searchTerm}`, {
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getSearchClient2024 = async (searchTerm) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/taxes2024/partners/clients/search?searchTerm=${searchTerm}`, {
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

