import React, {useEffect, useState} from "react";
import Spinner from "react-bootstrap/Spinner";
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {getUserById} from "../services/user";
import {getFondsREsubscribeByRoleWithEntranceFee} from "../services/Fond";
import {checkLastSubscribeForClient, editSubscribe, getListClientsSubOnlineByPartner} from "../services/Subscribe";
import PageContainer from "../components/PageContainer";
import '../css/ModifSouscription.css';


function ModifSouscription() {

    const [fonds, setFonds] = useState([]);
    const [selectedFonds, setSelectedFonds] = useState('');
    const [selectedDroit, setSelectedDroit] = useState('');
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isPartner, setIsPartner] = useState(null);

    const [role, setRole] = useState(null);
    const [clientError, setClientError] = useState(false);
    const [droitError, setDroitError] = useState(false);
    const [fondsError, setFondsError] = useState(false);
    const [validationTriggered, setValidationTriggered] = useState(false);
    const [droitsEntrees, setDroitsEntrees] = useState([]);
    const [isEditingSub, setisEditingSub] = useState(false);


    useEffect(() => {
        async function getFondsLoad() {

            const resUser = await getUserById();
            setRole(resUser.role);

            const fondsData = await getFondsREsubscribeByRoleWithEntranceFee();
            setFonds(fondsData);
            const allLinkEntranceFees = fondsData.flatMap(fond => fond.linkEntranceFees);
            setDroitsEntrees(allLinkEntranceFees);

            if (resUser.role === "conseiller") {
                const clientsData = await getListClientsSubOnlineByPartner();
                setClients(clientsData);
                //je le passe a true car il est conseiller
                setIsPartner(true)
            }

            if (resUser.role === "client") {
                //check si la derniere souscription est de France Valley
                const res = await checkLastSubscribeForClient();
                setIsPartner(res.isPartner);
            }
            setIsLoading(false)
        }
        getFondsLoad();
    }, []);


    useEffect(() => {
        setDroitError(!selectedDroit);
    }, [selectedDroit]);


    useEffect(() => {
        setFondsError(!selectedFonds);
    }, [selectedFonds]);

    useEffect(() => {
        setClientError(!selectedClient);
    }, [selectedClient]);



 const handleClientChange = (event) => {
        const clientId = parseInt(event.target.value);
        setSelectedClient(clients.find((c) => c.id === clientId));
    };

    const handleFondsChange = (event) => {
        const fondsId = parseInt(event.target.value);
        const selectedFonds = fonds.find((f) => f.id === fondsId);

        if (selectedFonds) {
            setSelectedFonds(selectedFonds);
            const droits = selectedFonds.linkEntranceFees;
            setDroitsEntrees(droits);
            setSelectedDroit('');  // réinitialiser le droit sélectionné
        } else {
            setSelectedFonds('');
        }
    };

    const handleDroitChange = (event) => {
        const droitId = parseInt(event.target.value);
        const foundDroit = droitsEntrees.find((d) => d.id === droitId);

        if (foundDroit) {
            setSelectedDroit(foundDroit);
        } else {
            setSelectedDroit('');
        }
    };

    const handleEditSubscribe = async (e) => {
        e.preventDefault();
    
        if (isEditingSub) return;

        setisEditingSub(true);
        setValidationTriggered(true); 
    
        if (!isFormValid()) {
            setisEditingSub(false); 
            return; 
        }

        const formData = collectFormData();
            try {
                const res = await editSubscribe(formData);
                if(res.status === 200) {
                    window.open(res.url, "_blank");
                }
            } catch (error) {
               throw error;
            } finally {
                setisEditingSub(false);
            }
      };

      const collectFormData = () => {
        const formData = new FormData();

        if (role === "conseiller") {
            formData.append("clientId", selectedClient.id);  
        }
        if (role === "conseiller" && droitsEntrees.length > 1 && selectedFonds ){
            formData.append('droitEntree', selectedDroit.entranceFee);
        }
        formData.append("fondId", selectedFonds.id);

        return formData;
    };

    const isFondsValid = () => !!selectedFonds;
    
    const isFormValid = () => {

        const validations = [
            isFondsValid(),
            role === "conseiller" && droitsEntrees.length > 1 ? !!selectedDroit : true,
            role === "conseiller" ? !!selectedClient : true,
        ]; 
        const formIsValid = validations.every(Boolean);
        
        setValidationTriggered(true);
        setFondsError(!isFondsValid());
        setDroitError(role === "conseiller" && droitsEntrees.length > 1 ? !selectedDroit : false);
        setClientError(role === "conseiller" ? !selectedClient : false);

        return formIsValid;
    };
    return (
        <PageContainer isLoading={isLoading}>
            {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <>
                    <Container className="my-5" >
                        <h1 className="text-center my-2" style={{ color: "#092A6C" }}>
                           REsouscription avec modifications
                        </h1>
                        {!isPartner ? (
                            <Row className="d-flex justify-content-center align-items-center">
                                <Col>
                                    <Card className="shadow my-4">
                                        <Card.Body>
                                            <h5 className="text-center mb-3" style={{ color: "#092A6C" }}>
                                                Information importante
                                            </h5>
                                            <p className="text-center">
                                                Vous avez été conseillé par un CGP (Conseiller en Gestion de Patrimoine), une Banque Privée ou un Family Office.
                                            </p>
                                            <p className="text-center">
                                                N'hésitez pas à le contacter pour qu'il vous présente l'offre actuelle de Fonds Forestiers et Viticoles de France Valley et pour qu'il vous transmette un lien Internet permettant de souscrire 100% en ligne.
                                            </p>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        ) : (
                        <Row className="d-flex justify-content-center align-items-center">
                            <Col lg={6}>
                                <Card className="shadow my-3">
                                    <Card.Body>
                                        <Form>
                                            {role === "conseiller" && (
                                                <Form.Group controlId="clients" className="mb-3">
                                                    <Form.Label>Vos Clients</Form.Label>
                                                    <Form.Control as="select" className="form-select" value={selectedClient?.id} onChange={handleClientChange} isInvalid={validationTriggered && clientError} required>
                                                        <option value="">Sélectionnez un client</option>
                                                        {clients.map((client) => (
                                                            <option key={client.id} value={client.id}>
                                                                {client.lastName} {client.firstName}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            )}
                                            <Form.Group controlId="fonds"className="mb-3">
                                                <Form.Label>Les Fonds</Form.Label>
                                                <Form.Control as="select" className="form-select" value={selectedFonds?.id} onChange={handleFondsChange}  isInvalid={validationTriggered && fondsError} required>
                                                    <option value="">Sélectionnez un fonds</option>
                                                    {fonds && fonds.map((fond) => (
                                                        <option key={fond.id} value={fond.id}>
                                                            {fond.nameFond}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                            {role === "conseiller" && droitsEntrees.length > 1 && selectedFonds && (
                                            <Form.Group controlId="droits" className="mb-3">
                                                <Form.Label>Les Droits d'entrée</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    className="form-select"
                                                    value={selectedDroit?.id}
                                                    onChange={handleDroitChange}
                                                    isInvalid={validationTriggered && droitError}
                                                    disabled={!selectedFonds}
                                                    required
                                                >
                                                    <option value="">Sélectionnez un droit d'entrée</option>
                                                    {droitsEntrees.map((droitEntree, index) => (
                                                        <option key={index} value={droitEntree.id}>
                                                            {droitEntree.entranceFee}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                            )}
                                            <Form.Group className="mb-2 text-center">
                                                <Button className="btn btn-custom" type="submit" onClick={handleEditSubscribe} disabled={isEditingSub} >
                                                {isEditingSub ? "Modification en cours..." : "Modifier la souscription"}
                                                </Button>
                                            </Form.Group>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        )}
                    </Container>
                </>
            )}
        </PageContainer>
    );
}

export default ModifSouscription;
