import axios from "axios";

export const getProductsByReportings = async () => {
    try {
        const response = await axios.get("https://extranet.france-valley.com/api/products/reportings" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getProductsByLastReportings = async () => {
    try {
        const response = await axios.get("https://extranet.france-valley.com/api/products/lastReportings" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const downloadFile = async (fileRelativePath) => {
    try {
        const encodedFilePath = encodeURIComponent(fileRelativePath);
        const response = await axios.get(`https://extranet.france-valley.com/api/downloadsFiles/${encodedFilePath}`, {
            responseType: "blob",
            withCredentials: true,
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const getProductsByAssembly = async () => {
    try {
        const response = await axios.get("https://extranet.france-valley.com/api/products/assembly" , { withCredentials: true });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const getProductsByCommunicated = async () => {
    try {
        const response = await axios.get("https://extranet.france-valley.com/api/products/communicated" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}
