import axios from 'axios';


export const getSubscribeByClientId = async (clientId, limit = 10, offset = 0) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/subscribes/client/${clientId}/subscribes?limit=${limit}&offset=${offset}`, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getSubscribeEndDateByClientId = async (clientId, limit = 10, offset = 0) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/subscribes/client/${clientId}/subscriptionEndDate?limit=${limit}&offset=${offset}`, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getSubscribeByPartnerId = async (partnerId, limit = 10, offset = 0) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/subscribes/partner/${partnerId}?limit=${limit}&offset=${offset}`, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getSubscribeEndDateByPartnerId = async (partnerId, limit = 10, offset = 0) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/subscribes/partner/${partnerId}/subscriptionEndDate?limit=${limit}&offset=${offset}`, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const getListClientsSubOnlineByPartner = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/subscribes/partners/clients/subscribeOnline" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const checkLastSubscribeForClient = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/subscribes/client/lastSub" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const getClientBySubscribeId = async (subscribeId) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/subscribes/partner/${subscribeId}/subscribes`, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const getStatsSubscribes = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/subscribes/clientAndPartner/stats" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getSearchClient = async (searchTerm) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/subscribes/partners/clients/search?searchTerm=${searchTerm}`, {
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const resubscribe = async (formData) => {
    try {
        const response = await axios.post(
            "https://dev.france-valley.paris/api/subscribes/resubscribe", formData, {
                withCredentials: true,
                headers: { "Content-Type": "multipart/form-data"},
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const editSubscribe = async (formData) => {
    try {
        const response = await axios.post(
            "https://dev.france-valley.paris/api/subscribes/editSubscribe", formData, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
};