import React from "react";
import {useState} from "react";
import axios from "axios";

const ModifyPDF = () => {
    const [formData, setFormData] = useState({});

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://127.0.0.1:3001/api/edit', formData);
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input type="text" name="Nom" placeholder="Nom" onChange={handleChange} />
            <input type="text" name="Prénom" placeholder="Prénom" onChange={handleChange} />
            <input type="email" name="E-mail" placeholder="E-mail" onChange={handleChange} />
            <button type="submit">Envoyer</button>
        </form>
    );
};

export default ModifyPDF;
