import React, { useEffect, useState } from "react";
import {Card, Col, Container, Form, ListGroup, Row} from "react-bootstrap";
import { getFondsByRoleWithEntranceFee } from "../services/Fond";
import "../css/SouscriptionEnLigne.css";
import Spinner from "react-bootstrap/Spinner";
import {checkLastSubscribeForClient} from "../services/Subscribe";
import {getUserById} from "../services/user";
import PageContainer from "../components/PageContainer";

function SouscriptionEnLigne() {

    const [user, setUser] = useState();
    const [fonds, setFonds] = useState([]);
    const [selectedFond, setSelectedFond] = useState(null);
    const [entranceFees, setEntranceFees] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isPartner, setIsPartner] = useState(null);
    const [showEntranceFees, setShowEntranceFees] = useState(true);

    useEffect(() => {
        async function getFondsLoad() {

            //recuperation du role du user
            const resUser = await getUserById();
            setUser(resUser);
            //recuperation des fonds en fonction du role
            const res = await getFondsByRoleWithEntranceFee();
            setFonds(res);

            if (resUser.role === "conseiller") {
                //je le passe a true car il est conseiller
                setIsPartner(true)
            }
            if (resUser.role === "client") {
                //check si la derniere souscription est de France Valley
                const res = await checkLastSubscribeForClient();
                setIsPartner(res.isPartner);
            }
            setIsLoading(false)
        }
        getFondsLoad();
    }, []);

    const handleFondChange = (e) => {
        const selectedFond = fonds.find(fond => fond.id === parseInt(e.target.value, 10));
        setSelectedFond(selectedFond);
    
        const shouldRedirect = (user.role === "client" || (user.role === "conseiller" && selectedFond && selectedFond.linkEntranceFees.length <= 1));
    
        if (selectedFond && shouldRedirect) {
            window.open(selectedFond.linkEntranceFees[0].url, "_blank");
            setShowEntranceFees(false);
        } else if (selectedFond) {
            setEntranceFees(selectedFond.linkEntranceFees || []);
            setShowEntranceFees(true);
        }
    };
    
    const handleEntranceFeeClick = (url) => {
        window.open(url, "_blank");
    };

    return ( 
        <PageContainer isLoading={isLoading}>
            {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
            <>
                <Container className="my-5">
                    <h1 className="text-center mb-4" style={{color: '#092A6C'}}>
                        {user.role === "client" ? "Souscrire en ligne" : "Souscription en ligne"}
                    </h1>
                    {!isPartner ? (
                        <Row>
                            <Col>
                                <Card className="shadow my-4">
                                    <Card.Body>
                                        <h5 className="text-center mb-3" style={{ color: "#092A6C" }}>
                                            Information importante
                                        </h5>
                                        <p className="text-center">
                                            Vous avez été conseillé par un CGP (Conseiller en Gestion de Patrimoine), une Banque Privée ou un Family Office.
                                        </p>
                                        <p className="text-center">
                                            N'hésitez pas à le contacter pour qu'il vous présente l'offre actuelle de Fonds Forestiers et Viticoles de France Valley et pour qu'il vous transmette un lien Internet permettant de souscrire 100% en ligne.
                                        </p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    ) : (
                    <Card className="shadow-lg my-3 souscription-card">
                        <Card.Body>
                            {user.role === "client" ? (
                                 <div className="mt-2 p-4 rounded border souscription-info">
                                 <p>
                                    France Valley a développé la souscription en ligne pour tous ses fonds d'investissement.
                                </p>
                                <p>
                                    <strong>Vous pouvez souscrire en ligne en cliquant sur un des fonds suivants :</strong>
                                </p>
                                    <ul>
                                        {fonds.map((fond) => (
                                            <li key={fond.id}>
                                                <strong>{fond.nameFond}</strong> : {fond.description}

                                            </li>
                                        ))}
                                    </ul>
                                 <p>
                                 Le Service Associés reste à votre disposition pour toute difficulté : 01 82 83 33 85 et associes@france-valley.com.
                                 </p>
                             </div>
                            ) : (
                                <div className="mt-2 p-4 rounded border souscription-info">
                                <h4 className="mb-3 souscription-subtitle">
                                    Les relevés Partenaires comprennent deux parties
                                </h4>
                                <p>
                                    Vous trouverez ci-dessous les liens de souscription en ligne aux fonds France Valley, qui sont différents en fonction du droit d'entrée que vous voudrez appliquer. Avertissement : indiquez bien à vos clients, pour le nom du conseiller, de mettre le nom de la structure et non pas le nom du conseiller personne physique.
                                </p>
                                <p>
                                    <strong>UTILE :</strong>
                                </p>
                                <ol>
                                    <li>
                                        <strong>Souscription en ligne à distance (préparée par le Partenaire) :</strong> Avec cet outil de souscription en ligne, vous pouvez préparer une souscription en ligne et envoyer par e-mail à votre client un lien de souscription pour qu'il signe en ligne le Bulletin. Pour ce faire, il suffit que vous alliez jusqu'à l'étape 6 (signature en ligne du bulletin de souscription) ; vous pourrez alors copiez/colliez le lien de souscription qui vous est donné et l'envoyer par e-mail à votre client. Lorsqu'il ouvrira ce lien, il pourra relire le bulletin de souscription et le signer en ligne. Vous recevrez alors automatiquement par e-mail le bulletin de souscription signé par votre client. Après traitement du dossier de souscription, les équipes Back Office vous transmettront par e-mail l'attestation de propriété (ou attestation fiscale).
                                    </li>
                                    <li>
                                        <strong>Souscription en ligne à distance (non préparée par le Partenaire) :</strong> Tout simplement, il vous suffit de transmettre à votre client le lien de souscription, pour qu'il suive en ligne le parcours de souscription de A à Z. Après traitement du dossier de souscription, les équipes Back Office vous transmettront par e-mail l'attestation de propriété (ou attestation fiscale).
                                    </li>
                                    <li>
                                        <strong>Souscription en rendez-vous physique :</strong> Vous pouvez également préparer un rendez-vous physique en pré-remplissant le bulletin de souscription, puis lors du rendez-vous, le faire relire à votre client et le faire signer en ligne.
                                    </li>
                                </ol>
                                <p>
                                    Un Justificatif d'Origine des Fonds sera demandé lorsque le total cumulé des souscriptions sur 12 mois sera supérieur à 90 000 euros : <a href="https://souscriptions.france-valley.com/JOF/" target="_blank"></a>le fournir en ligne.
                                </p>
                            </div>
                            )}
                           
                            <div className="mt-3">
                            <Form>
                                <Form.Group controlId="fondsSelect">
                                    <Form.Label>Les Fonds :</Form.Label>
                                    <Form.Control
                                        as="select"
                                        className="form-select shadow-sm"
                                        onChange={handleFondChange}
                                    >
                                        <option value="">Sélectionnez un fonds</option>
                                        {fonds.map((fond) => (
                                            <option key={fond.id} value={fond.id}>
                                                {fond.nameFond}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                            </div>
                            {selectedFond && showEntranceFees && (
                                <div className="mt-3">
                                    <h5>Les droits d'entrée :</h5>
                                    <ListGroup className="entrance-fees-list">
                                        {entranceFees.map((entranceFee) => (
                                            <ListGroup.Item
                                                key={entranceFee.id}
                                                action
                                                onClick={() => handleEntranceFeeClick(entranceFee.url)}
                                                className="entrance-fee-item"
                                            >
                                                {entranceFee.entranceFee}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                    )}
                </Container>
            </>
            )}

        </PageContainer>
    );
}

export default SouscriptionEnLigne;
