import axios from "axios";

export const loginReporting = async (data) => {

    try {
        const response = await axios.post('https://extranet.france-valley.com/api/reportings/login', data, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const checkLogin = async () => {
    try {
        const response = await axios.get("https://extranet.france-valley.com/api/reportings/logged", { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const downloadFileReporting = async (fileRelativePath) => {
    try {
        const encodedFilePath = encodeURIComponent(fileRelativePath);
        const response = await axios.get(`https://extranet.france-valley.com/api/reportings/${encodedFilePath}`, {
            responseType: "blob",
            withCredentials: true,
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const getReportings = async () => {
    try {
        const response = await axios.get("https://extranet.france-valley.com/api/reportings/reportings" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getAssembly = async () => {
    try {
        const response = await axios.get("https://extranet.france-valley.com/api/reportings/assembly" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getCommunicated = async () => {
    try {
        const response = await axios.get("https://extranet.france-valley.com/api/reportings/communicated" , { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}