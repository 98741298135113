import axios from 'axios';

export const getFondsDoccenter = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/doccenter/fonds", { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const checkLogin = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/doccenter/logged", { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getFondDocumentsDoccenterByFondId = async (fondId) => {
    try {
        const response = await axios.get(`https://dev.france-valley.paris/api/doccenter/fonds/documents/${fondId}`, { withCredentials: true });
        console.log(response);
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const getSyntheseFondsDoccenter = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/doccenter/synthese", { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getDocEngagementForestiers = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/doccenter/docEngagementForestiers", { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getSimulations = async () => {
    try {
        const response = await axios.get("https://dev.france-valley.paris/api/doccenter/simulations" , { withCredentials: true });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const loginDoccenter = async (data) => {

    try {
        const response = await axios.post('https://dev.france-valley.paris/api/doccenter/login', data, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const downloadFileDoccenter = async (fileRelativePath) => {
    try {
        const encodedFilePath = encodeURIComponent(fileRelativePath);
        const response = await axios.get(`https://dev.france-valley.paris/api/doccenter/${encodedFilePath}`, {
            responseType: "blob",
            withCredentials: true,
        });
        return response;
    } catch (error) {
        throw error;
    }
};