import React from "react";
import ChangePassword from "../components/ChangePassword";

function ChangePasswordClient() {
    return (
        <>
            <ChangePassword type="client" />
        </>
    );
}


export default ChangePasswordClient;
